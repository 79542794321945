import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import { Button, Col, Row } from 'reactstrap';
import { capitalize } from '../../../../../../global-helpers/global.helpers';
import { imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';
import { svgFuIconByContentType } from '../../../../../Resources/Lists/ListsContent/list-content.helper';
import placeholderImage from '../../../../../../assets/img/image-placeholder.png';
import { useTranslation } from 'react-i18next';

type Properties = {
	selectedListItem: ListItemModel;
	itemIndex: number;
	shouldShowListImages: boolean;
	onDelete: (itemType: string, itemId: string) => void;
};

const FuListContentSortableItem: FunctionComponent<Properties> = ({ selectedListItem, itemIndex, shouldShowListImages, onDelete }) => {
	const [t] = useTranslation();

	const formatContentTypeLabel = (type: string): string => {
		switch (type) {
			case 'POLL':
				return 'Poll';
			case 'MATCH_QUIZ':
				return 'Match Quiz';
			case 'CLASSIC_QUIZ':
				return 'Classic Quiz';
			default:
				return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
		}
	};

	if (!selectedListItem) return null;
	const selectedListItemData = selectedListItem.data;
	const isLocked = typeof selectedListItem.lockedPosition === 'number';
	const contentIcon = selectedListItemData && selectedListItemData.type && svgFuIconByContentType[selectedListItemData.type.toLowerCase()];
	const contentImage = selectedListItemData.images && selectedListItemData.images.main ? selectedListItemData.images.main : placeholderImage;
	const type = `${formatContentTypeLabel(selectedListItemData.type)}`;

	return (
		<li className={`list-group-item mb-1 p-1 ${isLocked && 'disabled'}`}>
			<Row className='position-relative '>
				<Col className='d-flex justify-content-between align-items-center'>
					<div className='list-content d-flex justify-content-between align-items-center w-80'>
						{isLocked && <i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />}
						<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
						<div className='d-inline-flex w-100'>
							<div className='d-flex align-items-center overflow-hidden'>
								{shouldShowListImages && (
									<div className='image-wrapper mr-2'>
										<img
											src={contentImage}
											height='60'
											width='60'
											className='max-width-100 rounded-circle'
											onError={(i: any) => (i.target.src = imagePlaceholderBase64)}
											alt={selectedListItemData.title}
										/>
									</div>
								)}
								<div className='content-wrapper overflow-hidden'>
									<h6 className='mb-0'>
										<img alt={capitalize(selectedListItemData.type)} src={contentIcon} />
										<label className='text-dark ml-1 mb-0'>[{selectedListItemData.title}]</label>
									</h6>
									<label className='ml-1 mt-1'>{`${t('type')}:`}</label> <label className='type-label'>{type}</label>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col className='action-buttons'>
					<div>
						<Button
							disabled={isLocked}
							id={`content-item-image-delete-btn-${itemIndex}`}
							color='danger'
							onClick={() => onDelete(selectedListItem.type, selectedListItemData.id)}
							className='mb-0'
						>
							<i className='fa fa-trash' />
						</Button>
					</div>
				</Col>
			</Row>
		</li>
	);
};

function mapStateToProps(state: any) {
	return {
		shouldShowListImages: state.ui.shouldShowListImages[state.profile.profile.id] || false,
		profileId: state.profile.profile.id || '',
	};
}

export default connect(mapStateToProps)(FuListContentSortableItem);
