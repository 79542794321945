import { featuresService } from '../../../../../App';
import { ContentTypes } from '../../../../../constants/content-types';
import { isTempIdOrEmpty } from '../../../../../global-helpers/global.helpers';
import ListModel from '../../../../../models/v2/list/list';
import ListItemModel from '../../../../../models/v2/list/list-item';
import { responseToModelList } from '../../../../../models/v2/list/list.mapper';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import HttpService from '../../../../../services/rest/HttpService';
import { store } from '../../../../../store/store';
import { generateStatisticsQueryParams, populateListItemsWithContentStatistics, populateListLockedPositions } from './general.helper';

export const checkIfContentIsEditorial = () => {
	const windowLocationHash = window.location.hash;
	if (
		windowLocationHash.includes('/smp/articles/edit/') ||
		windowLocationHash.includes('/smp/articles/create') ||
		windowLocationHash.includes('/smp/galleries/create') ||
		windowLocationHash.includes('/smp/galleries/edit/') ||
		windowLocationHash.includes('/smp/videos/create') ||
		windowLocationHash.includes('/smp/videos/edit/')
	) {
		return true;
	} else {
		return false;
	}
};

export const requestListsContent = (page: string, limit: string, header: any) => {
	if (checkIfContentIsEditorial()) {
		return HttpService.get(`/v2/lists?page=${page}&content_type=editorial&limit=${limit}`, null, header);
	} else {
		return HttpService.get(`/v2/lists?page=${page}&limit=${limit}`, null, header);
	}
};

export const getListsContent = (projectDomain: string, setStateFunc: Function, page: string = '1', limit: string = '200') => {
	const header = { Project: projectDomain };

	requestListsContent(page, limit, header).then((response: any) => {
		const listsFromResponse = response.data.data;
		const remappedLists = listsFromResponse.map((respList: any) => responseToModelList(respList));
		setStateFunc(remappedLists);
	});
};

export const constructContentStatisticsSearchQuery = (listItems: ListItemModel[]) => {
	if (listItems && listItems.length > 0) {
		const articlesQuery = generateStatisticsQueryParams(listItems, ContentTypes.ARTICLE);
		const videosQuery = generateStatisticsQueryParams(listItems, ContentTypes.VIDEO);
		const galleriesQuery = generateStatisticsQueryParams(listItems, ContentTypes.GALLERY);
		const statisticsQuery = `${articlesQuery}${videosQuery}${galleriesQuery}`.substring(1);
		return `?${statisticsQuery}`;
	}

	return '';
};

export const populateListContentStatistics = (contentStatisticsConfig: any, list: ListModel): Promise<ListModel> => {
	const listItems = list.items || [];
	return HttpService.getContentStatistics(
		contentStatisticsConfig.request_headers[0],
		`${contentStatisticsConfig.url}${constructContentStatisticsSearchQuery(listItems)}`,
	)
		.then((resp: any) => {
			const respContentStatistics = resp.data;
			const populatedListWithStatistics = populateListItemsWithContentStatistics(respContentStatistics, list);
			return populatedListWithStatistics;
		})
		.catch((e: any) => console.log(e));
};

export const getListDetails = (listId: string, projectDomain: string): Promise<ListModel> => {
	const headers = { Project: projectDomain };
	return HttpService.get(`/lists/${listId}`, null, headers)
		.then((resp: any) => {
			const respListData = resp.data.data;
			const remappedListData = responseToModelList(respListData);

			// the check below is only to filter if null/undefined values are in array
			// (it will only occurs if we have already broken list - check SFE-3758)
			if (remappedListData && remappedListData.configuration && remappedListData.configuration.sports) {
				remappedListData.configuration.sports = remappedListData.configuration.sports.filter((el) => el !== null && el !== undefined);
			}

			const remappedListDataWithPopulatedLockedItems = populateListLockedPositions(remappedListData);
			return remappedListDataWithPopulatedLockedItems;
		})
		.catch((e: any) => console.log(e));
};

export const getListDetailsAndPopulateWithStatistics = async (selectedListId: string, projectDomain: string): Promise<ListModel> => {
	const remappedListData = await getListDetails(selectedListId, projectDomain);

	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
		if (remappedListData && remappedListData.items.length > 0) {
			const nonFansUnitedItems = remappedListData.items.filter((item: ListItemModel) => item.type !== 'FANS_UNITED');

			if (nonFansUnitedItems.length > 0) {
				const contentStatisticsConfigUrl = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS) || {};
				const remappedListDataWithPopulatedStatistics = await populateListContentStatistics(contentStatisticsConfigUrl, remappedListData);
				return remappedListDataWithPopulatedStatistics;
			}
		}
	}

	return remappedListData;
};

export const getPresentInData = (
	contentModelId: string,
	projectDomain: string,
	setIsLoading: (value: boolean) => void,
	setListPresentIn: (data: ListModel[]) => void,
) => {
	const reduxListState = store.getState().list;
	const listLength = (reduxListState && reduxListState.list && reduxListState.list.items && reduxListState.list.items.length) || 0;

	if (!isTempIdOrEmpty(contentModelId) && listLength > 0) {
		const headers = { Project: projectDomain };
		setIsLoading(true);
		HttpService.get(`/lists/search?query=*&itemIds=${contentModelId}`, null, headers)
			.then((response: any) => {
				const listsFromResponse = response.data.data;
				const remappedListData = listsFromResponse.map((respList: any) => responseToModelList(respList)) || [];
				setListPresentIn(remappedListData);
				setIsLoading(false);
			})
			.catch((error: unknown) => console.error(error));
	}
};
