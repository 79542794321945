import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { GenericOptionType } from '../../../../constants/select';

type Properties = {
	inputId: string;
	isClearable?: boolean;
	options: GenericOptionType<string>[];
	editorialTypes: string[];
	onEditorialTypesSelect: (value: string[]) => void;
};

const EditorialTypeItemsMultiselect: React.FunctionComponent<Properties> = (props) => {
	const { inputId, isClearable, options, editorialTypes, onEditorialTypesSelect } = props;
	const [t] = useTranslation();
	const [selectedOptions, setSelectedOptions] = useState<GenericOptionType<string>[]>([]);

	useEffect(() => {
		if (editorialTypes) {
			const selectedOptions = options.filter((option) => editorialTypes.includes(option.value));
			setSelectedOptions(selectedOptions);
		}
	}, [editorialTypes]);

	const onChange = (selected: GenericOptionType<string>[] | null) => {
		const updatedSelectedOptions = selected || [];
		const selectedValues = updatedSelectedOptions.map((option) => option.value);

		setSelectedOptions(updatedSelectedOptions);
		onEditorialTypesSelect(selectedValues);
	};

	return (
		<>
			<Select
				inputId={inputId}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				isClearable={isClearable}
				placeholder={t('select')}
				options={options}
				value={selectedOptions}
				isMulti
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				onChange={(selected) => onChange(selected as GenericOptionType<string>[] | null)}
				styles={{
					menuPortal: (baseStyles) => ({
						...baseStyles,
						zIndex: 10000,
					}),
				}}
				className={'mb-1'}
			/>
		</>
	);
};

export default EditorialTypeItemsMultiselect as React.ComponentType<Properties>;
