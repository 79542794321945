import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, Col, Row } from 'reactstrap';
import ListItemMeta from '../../../../../models/list/list-item/list-item-meta/ListItemMeta';
import Project from '../../../../../models/project/Project';
import { ContentItemOverride } from './ContentItemOverride';
import { useSelector } from 'react-redux';
import '../subcomponents/Scss/ContentItemData.scss';
import { useTranslation } from 'react-i18next';

export type ContentFuItemModel = {
	listItemId: string;
	title: string;
	publishedAt: string;
	icon: string;
	meta: ListItemMeta;
	type: string;
	entityType: string;
	status: string;
	image: string;
};

type Properties = {
	value: ContentFuItemModel;
	project: Project;
	onDelete: () => void;
	onEdit: () => void;
	onClear: () => void;
	rightSidebarList: boolean;
	itemIndex: number;
	isLocked: boolean;
};

const DragHandle = SortableHandle(() => (
	<div className={'cursor-draggable mr-2'}>
		<i className='fa fa-bars'></i>
	</div>
));

const FuContentItem: React.FC<Properties> = ({ value, project, onClear, onEdit, onDelete, rightSidebarList, itemIndex, isLocked }) => {
	const shouldShowListImages = useSelector((state: any) => state.ui.shouldShowListImages);
	const profileId = useSelector((state: any) => state.profile.profile.id);
	const [t] = useTranslation();

	const isTagOrSportsConnection = (type: string) => {
		return type === 'TAG' || type === 'RELATEDCONTENT';
	};

	const formatContentTypeLabel = (type: string): string => {
		switch (type) {
			case 'POLL':
				return 'Poll';
			case 'MATCH_QUIZ':
				return 'Match Quiz';
			case 'CLASSIC_QUIZ':
				return 'Classic Quiz';
			default:
				return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
		}
	};

	const style = !rightSidebarList ? 'd-inline-block w-100' : 'd-inline-flex w-100';
	const styleList = rightSidebarList ? 'mb-1 p-1' : '';
	const type = `${formatContentTypeLabel(value.entityType)}`;

	return (
		<li className={`list-group-item ${styleList} ${isLocked ? 'disabled' : ''}`}>
			<Row className='position-relative'>
				<Col className={`d-flex justify-content-between ${rightSidebarList ? 'align-items-center' : ''}`}>
					<div className='list-content d-flex justify-content-between align-items-center w-80'>
						{isLocked && <i style={{ left: '50%' }} className='position-absolute p-1 fa-4x opacity-02 fa fa-lock text-muted' />}
						<span className='px-1 font-weight-bold'>{itemIndex + 1}.</span>
						{!rightSidebarList && <DragHandle />}
						<div className={`${style}`}>
							<div className={'d-flex align-items-center overflow-hidden'}>
								{!rightSidebarList && value.type !== 'TAG' && (
									<div className={'image-wrapper mr-2'}>
										<img alt={`${value.title}-${value.listItemId}`} src={value.image} className={'max-width-100 rounded'} />
									</div>
								)}
								{rightSidebarList && shouldShowListImages[profileId] && (
									<div className='image-wrapper mr-2'>
										<img alt={`${value.title}-${value.listItemId}`} src={value.image} height='60' width='60' className='max-width-100' />
									</div>
								)}
								<div className={'content-wrapper overflow-hidden'}>
									<h6 className='mb-0'>
										<img alt={`${value.title}-${value.listItemId}`} src={value.icon} className='max-width-100' />
										<label className='list-item-title ml-1'>{value.title}</label>
									</h6>
									<label className='type-label-placeholder ml-1'>{`${t('type')}:`}</label> <label className='type-label'>{type}</label>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col className='action-buttons'>
					<div>
						{!rightSidebarList && (
							<>
								{!isTagOrSportsConnection(value.type) && (
									<Button
										style={{ pointerEvents: 'all' }}
										id={`content-item-image-edit-btn-${itemIndex}`}
										color={'warning'}
										onClick={() => onEdit()}
										className='mr-1 mb-1 w-100'
									>
										<i className={'fa fa-pencil'}></i> {t('edit')}
									</Button>
								)}
								<Button
									disabled={isLocked}
									id={`content-item-image-remove-btn-${itemIndex}`}
									color={'danger'}
									onClick={() => onDelete()}
									className='mb-1 w-100'
								>
									<i className={'fa fa-trash'}></i> {t('remove')}
								</Button>
							</>
						)}
						{rightSidebarList && (
							<Button
								disabled={isLocked}
								id={`content-item-image-delete-btn-${itemIndex}`}
								color={'danger'}
								onClick={() => onDelete()}
								className={'mb-0'}
							>
								<i className={'fa fa-trash'}></i>
							</Button>
						)}
					</div>
				</Col>
			</Row>
			{!rightSidebarList && (
				<Row>
					<Col>
						<ContentItemOverride t={t} project={project} meta={value.meta} onClear={() => onClear()} />
					</Col>
				</Row>
			)}
		</li>
	);
};

export default FuContentItem;
