import { PopularitySettingsJson } from './PopularitySettingsJson';
import PopularitySettingsBuilder from './PopularitySettingsBuilder';

export default class PopularitySettings {
	readonly timeRange: string;
	readonly dataSource: string;
	readonly frequency: string;

	private constructor(timeRange: string, dataSource: string, frequency: string) {
		this.timeRange = timeRange;
		this.dataSource = dataSource;
		this.frequency = frequency;
	}

	toJSON(): PopularitySettingsJson {
		return {
			timeRange: this.timeRange,
			dataSource: this.dataSource,
			frequency: this.frequency,
		};
	}

	static fromJSON(json: PopularitySettingsJson): PopularitySettings {
		return new PopularitySettings(json.timeRange, json.dataSource, json.frequency);
	}

	static builder(settings?: PopularitySettings): PopularitySettingsBuilder {
		return new PopularitySettingsBuilder(settings);
	}
}
