import React from 'react';
import ContentItem from './ContentItem';
import ListItem from '../../../../../models/list/list-item/ListItem';
import Project from '../../../../../models/project/Project';
import ContentItemOverrideEdit from './ContentItemOverrideEdit';
import ListItemMeta from '../../../../../models/list/list-item/list-item-meta/ListItemMeta';
import { ContentOverrideModel } from './models/ContentOverrideModel';
import { cssIconByContentType } from '../list-content.helper';
import { constructContentItemModel, constructFuContentItemModel } from './helpers/content-item-container.helper';
import { getListType } from '../../helpers/list.helper';
import { ListContentTypes } from '../../../../../constants/content-types';
import { isFuOption } from '../../../../Partials/Shared/search-content-by-type/helpers/search-content-by-type-container.helper';
import FuContentItem from './FuContentItem';

type Properties = {
	value: ListItem;
	project: Project;
	onDelete: (itemIndex: number) => any;
	onMetaChange: (meta: ListItemMeta) => any;
	itemIndex: number;
	t: any;
	rightSidebarList: boolean;
	isLocked: boolean;
	onListItemContentTypeChange: (item: ListItem) => void;
};

type State = {
	isOverrideEdit: boolean;
};

export default class ContentItemContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			isOverrideEdit: false,
		};
	}

	toggleOverrideEditState = () => this.setState((state) => ({ ...state, isOverrideEdit: !state.isOverrideEdit }));

	itemHasOverrideValues(item: ListItem): boolean {
		if (item.meta) {
			const hasTitle = item.meta.title !== undefined && item.meta.title !== null && item.meta.title.length > 0;
			const hasSubtitle = item.meta.subtitle !== undefined && item.meta.subtitle !== null && item.meta.subtitle.length > 0;
			const hasImageId = item.meta.imageId !== undefined && item.meta.imageId !== null && item.meta.imageId.length > 0;
			return hasTitle || hasSubtitle || hasImageId;
		}

		return false;
	}

	constructListItemMeta(model: ContentOverrideModel): ListItemMeta {
		const listItemMeta = ListItemMeta.builder().withImageId(model.imageId).withSubtitle(model.subtitle).withTitle(model.title);
		if (getListType() === ListContentTypes.SCHEDULED) {
			listItemMeta.withFromDate(model.from).withToDate(model.to);
		}

		return listItemMeta.build();
	}

	onMetaChange(model: ContentOverrideModel) {
		const listItemMeta = this.constructListItemMeta(model);
		this.props.onMetaChange(listItemMeta);
	}

	onMetaClear() {
		this.props.onMetaChange({} as ListItemMeta);
	}

	onContentSwitch = (item: ListItem) => {
		this.props.onListItemContentTypeChange(item);
	};

	constructContentItemOverrideModel(listItemMeta: ListItemMeta, contentType: string, listItem: ListItem): ContentOverrideModel {
		return {
			icon: cssIconByContentType[contentType && contentType.toLowerCase()],
			imageId: listItemMeta && listItemMeta.imageId,
			subtitle: listItemMeta && listItemMeta.subtitle,
			title: listItemMeta && listItemMeta.title,
			listItemTitle: listItemMeta && listItem.data && listItem.data.title,
			listItemId: listItemMeta && listItem.data && listItem.data.id,
			listItemType: contentType,
			from: listItemMeta && listItemMeta.from,
			to: listItemMeta && listItemMeta.to,
		};
	}

	render() {
		const { isOverrideEdit } = this.state;
		const { value, t, project, rightSidebarList, itemIndex, isLocked } = this.props;
		const contentItemModel = constructContentItemModel(value);
		const fuContentItemModel = constructFuContentItemModel(value);

		const contentItemOverrideModel = this.constructContentItemOverrideModel(value.meta, value.type, value);

		const isFuContent = isFuOption(value.data && value.data.type && value.data.type.toLocaleLowerCase());

		return (
			<div>
				{isOverrideEdit ? (
					<ContentItemOverrideEdit
						itemIndex={itemIndex}
						onChange={this.onMetaChange.bind(this)}
						onClose={this.toggleOverrideEditState}
						project={project}
						value={contentItemOverrideModel}
						t={t}
						rightSidebarList={rightSidebarList}
					/>
				) : !isFuContent ? (
					<ContentItem
						value={contentItemModel}
						t={t}
						onEdit={this.toggleOverrideEditState}
						onClear={() => this.onMetaClear()}
						project={project}
						onDelete={() => this.props.onDelete(this.props.itemIndex)}
						rightSidebarList={rightSidebarList}
						itemIndex={this.props.itemIndex}
						isLocked={isLocked}
						onChange={() => this.onContentSwitch(value)}
					/>
				) : (
					<FuContentItem
						value={fuContentItemModel}
						onEdit={this.toggleOverrideEditState}
						onClear={() => this.onMetaClear()}
						project={project}
						onDelete={() => this.props.onDelete(this.props.itemIndex)}
						rightSidebarList={rightSidebarList}
						itemIndex={itemIndex}
						isLocked={isLocked}
					/>
				)}
			</div>
		);
	}
}
