import axios from 'axios';
import { fansUnitedService, featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';

export default class FansUnitedHttpService {
	static instance = (baseUrl: string) => {
		return axios.create({
			baseURL: baseUrl,
			timeout: 30000,
		});
	};

	static disabledCache() {
		return `&disable_cache=${Math.floor(Date.now() / 1000)}`;
	}

	static getGames = (type: string, limit?: number, startAfter?: string) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const configUrl = (config && config.configuration && config.configuration.prediction_url) || 'https://prediction.fansunitedapi.com/v1';
		const disabledCache = FansUnitedHttpService.disabledCache();
		const limitQuery = limit ? `&limit=${limit}` : '';
		const startAfterQuery = startAfter ? `&start_after=${startAfter}` : '';

		if (fansUnitedService.fansUnitedCredentialsPresent()) {
			return FansUnitedHttpService.instance(configUrl).get(
				`/games?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}&type=${type}${limitQuery}${startAfterQuery}&status=OPEN,PENDING${disabledCache}`,
			);
		}
	};

	static getLeaderboardTemplates = () => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const configUrl = (config && config.configuration && config.configuration.loyalty_url) || 'https://loyalty.fansunitedapi.com/v1';
		const disabledCache = FansUnitedHttpService.disabledCache();
		if (fansUnitedService.fansUnitedCredentialsPresent()) {
			return FansUnitedHttpService.instance(configUrl).get(
				`/leaderboard/templates?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}${disabledCache}`,
			);
		}
	};

	static getClassicQuizzes = (limit?: number, startAfter?: string) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const configUrl = (config && config.configuration && config.configuration.quizzes_url) || 'https://mini-games.fansunitedapi.com/v1';
		const disabledCache = FansUnitedHttpService.disabledCache();
		const limitQuery = limit ? `&limit=${limit}` : '';
		const startAfterQuery = startAfter ? `&start_after=${startAfter}` : '';

		if (fansUnitedService.fansUnitedCredentialsPresent()) {
			return FansUnitedHttpService.instance(configUrl).get(
				`/classic-quizzes?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}${limitQuery}${startAfterQuery}&status=ACTIVE${disabledCache}`,
			);
		}
	};

	static getEitherOrGames = () => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const configUrl = (config && config.configuration && config.configuration.eitherOr_url) || 'https://mini-games.fansunitedapi.com/v1';
		const disabledCache = FansUnitedHttpService.disabledCache();
		if (fansUnitedService.fansUnitedCredentialsPresent()) {
			return FansUnitedHttpService.instance(configUrl).get(
				`/either-or?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}${disabledCache}`,
			);
		}
	};

	static getPolls = (limit?: number, startAfter?: string) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.FANS_UNITED);
		const configUrl = (config && config.configuration && config.configuration.polls_url) || 'https://voting.fansunitedapi.com/v1';
		const disabledCache = FansUnitedHttpService.disabledCache();
		const limitQuery = limit ? `&limit=${limit}` : '';
		const startAfterQuery = startAfter ? `&start_after=${startAfter}` : '';

		if (fansUnitedService.fansUnitedCredentialsPresent()) {
			return FansUnitedHttpService.instance(configUrl).get(
				`/polls?key=${config.configuration.api_key}&client_id=${config.configuration.client_id}${limitQuery}${startAfterQuery}&status=ACTIVE${disabledCache}`,
			);
		}
	};
}
