import { AutomaticListCriteriaRequestJson } from './AutomaticListCriteriaRequestJson';
import { AutomaticListCriteriaJson } from './AutomaticListCriteriaJson';
import AutomaticListCriteria from './AutomaticListCriteria';

export default class AutomaticListCriteriaBuilder {
	private json: AutomaticListCriteriaJson;

	constructor(automaticListCriteria?: AutomaticListCriteria | AutomaticListCriteriaJson) {
		if (automaticListCriteria && automaticListCriteria instanceof AutomaticListCriteria) {
			this.json = automaticListCriteria.toJSON();
		} else if (automaticListCriteria) {
			this.json = automaticListCriteria;
		} else {
			this.json = {} as AutomaticListCriteriaJson;
		}
	}

	withEditorialTypes(editorialTypes: string[]): AutomaticListCriteriaBuilder {
		this.json.entityTypes = editorialTypes;

		return this;
	}

	build(): AutomaticListCriteria {
		return AutomaticListCriteria.fromJSON(this.json);
	}

	equals(automaticListCriteria: AutomaticListCriteria): boolean {
		if (automaticListCriteria && this.json) {
			const entityTypesEqual = this.json.entityTypes === automaticListCriteria.entityTypes;

			return entityTypesEqual;
		}

		return true;
	}

	toRequestJson(): AutomaticListCriteriaRequestJson {
		let json = {} as AutomaticListCriteriaRequestJson;
		json.entity_types = this.json.entityTypes;

		return json;
	}
}
