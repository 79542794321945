import { Label } from 'reactstrap';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import List from '../../../../../models/list/List';
import ListItem from '../../../../../models/list/list-item/ListItem';
import ListItemAddButtons from '../../../Sidebar/lists/subcomponents/list-item-add-buttons.component';
import { svgFuIconByContentType } from '../../../../Resources/Lists/ListsContent/list-content.helper';
import { capitalize } from '../../../../../global-helpers/global.helpers';
import { useTranslation } from 'react-i18next';
import FansUnitedHttpService from '../../../Blocky/blocks/widgets/fans-united/services/fans-united-http.service';
import { relatedTypesMap, responseToFuOptions } from '../helpers/search-content-by-type-container.helper';
import { FuContentTypes } from '../../../../../constants/content-types';
import StatusPill from '../../../design-components/status-pill/status-pill.component';
import './styles.scss';

type Properties = {
	type: string;
	onRelatedContentSelect: (option: any, addItemToTop?: boolean) => void;
	list: List;
	listItems: ListItem[];
	toggleFirstLockPositionError: (toggleError: boolean) => void;
	showAddToTopToggle: boolean;
};

const FuSuggestedListOptions: React.FC<Properties> = ({
	type,
	onRelatedContentSelect,
	list,
	listItems,
	toggleFirstLockPositionError,
	showAddToTopToggle,
}) => {
	const [t] = useTranslation();
	const limit = 20;
	const [isLoading, setLoading] = useState(false);
	const [options, setOptions] = useState<Record<string, any>[]>([]);
	const [currentLimit, setCurrentLimit] = useState(limit);
	const [startAfter, setStartAfter] = useState<string | null>(null); // Track the ID of the last element
	const [hasMore, setHasMore] = useState(true);
	const observer = useRef<IntersectionObserver | null>(null);

	const getContent = useCallback(
		(type: string, limit: number, startAfter: string | null) => {
			setLoading(true);

			const fetchContent = () => {
				switch (type) {
					case FuContentTypes.CLASSIC_QUIZ:
						return FansUnitedHttpService.getClassicQuizzes(limit, startAfter);
					case FuContentTypes.POLL:
						return FansUnitedHttpService.getPolls(limit, startAfter);
					default:
						return FansUnitedHttpService.getGames('MATCH_QUIZ', limit, startAfter);
				}
			};

			fetchContent()
				.then((response) => {
					const newOptions = responseToFuOptions(response.data.data, relatedTypesMap[type]);

					setOptions((prevOptions) => [...prevOptions, ...newOptions]);
					if (response.data.data.length < limit) {
						setHasMore(false);
					} else {
						const lastElement = response.data.data[response.data.data.length - 1];
						setStartAfter(lastElement.id);
					}

					setLoading(false);
				})
				.catch(() => setLoading(false));
		},
		[type],
	);

	useEffect(() => {
		setOptions([]);
		setHasMore(true);
		setStartAfter(null);
		setCurrentLimit(limit);

		getContent(type, limit, null);
	}, [type]);

	useEffect(() => {
		if (currentLimit > limit) {
			getContent(type, currentLimit, startAfter);
		}
	}, [currentLimit, startAfter]);

	const lastOptionElementRef = useCallback(
		(node) => {
			if (isLoading || !hasMore) return; // Avoid requests if loading or no more data
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setCurrentLimit((prevLimit) => Math.min(prevLimit + limit, 100)); // Increment limit for the next fetch
				}
			});
			if (node) observer.current.observe(node);
		},
		[isLoading, hasMore],
	);

	return (
		<div className='my-2'>
			<Label>
				<strong>{t(type)}:</strong>
			</Label>
			<ul className={`${isLoading ? 'loading-overlay' : ''} list-group`}>
				{options.length > 0 ? (
					options
						.filter((option) => !listItems.find((el) => el.data && el.data.id === option.value))
						.map((option, index) => {
							const lastElementRef = index === options.length - 1 ? lastOptionElementRef : null;
							return (
								<li
									ref={lastElementRef}
									key={`option-data-${option.data && option.data.id}-${index}`}
									className='list-group-item d-flex align-items-center'
								>
									<div className='mr-2'>
										<ListItemAddButtons
											t={t}
											currentContent={option}
											list={list}
											onListItemAdd={onRelatedContentSelect}
											toggleFirstLockPositionError={toggleFirstLockPositionError}
											showAddToTopToggle={showAddToTopToggle}
										/>
									</div>
									<img alt={capitalize(option.type)} src={svgFuIconByContentType[option.type]} style={{ marginRight: '8px', marginLeft: '8px' }} />
									<div className='d-flex flex-column mr-auto'>
										<div className='d-flex align-items-center'>
											<strong className='p-1'>{`[${option.data && option.data.title}]`}</strong>
											<StatusPill status={option.data && option.data.status} />
										</div>

										{option.type === 'poll' && (
											<div className='d-flex pl-1 pr-1'>
												<div key={`${option.type}-${option.title}`} className='pr-2 d-flex'>
													<p className='pr-1 mb-0'>{`${t('votes_count')}: `}</p>
													<strong>{option.data && option.data.total_votes}</strong>
												</div>
											</div>
										)}
										{option.type === FuContentTypes.CLASSIC_QUIZ && (
											<div className='d-flex pl-1 pr-1'>
												<div key={`${option.type}-${option.title}`} className='pr-2 d-flex'>
													<p className='pr-1 mb-0'>{`${t('questions_count')}: `}</p>
													<strong>{option.data && option.data.questions_count}</strong>
												</div>
											</div>
										)}
										{option.type === FuContentTypes.MATCH_QUIZ && (
											<div className='d-flex pl-1 pr-1'>
												<div key={`${option.type}-${option.title}`} className='pr-2 d-flex'>
													<p className='pr-1 mb-0'>{`${t('participants_count')}: `}</p>
													<strong>{option.data && option.data.participants_count}</strong>
												</div>
											</div>
										)}
									</div>
									<small className='created-at-label'>{`${t('created_at')}: ${moment(option.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</small>
								</li>
							);
						})
				) : (
					<div className='text-center text-muted p-3'>{t('no_data_found')}</div>
				)}
			</ul>
		</div>
	);
};

export default FuSuggestedListOptions;
