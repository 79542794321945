import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Properties } from './properties/search-content-by-type.properties';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes, FuContentTypes } from '../../../../constants/content-types';
import { useTranslation } from 'react-i18next';
import './style/search-options.scss';

const SearchContentByType: React.FC<Properties> = ({ onSearchTypeSelect, type }) => {
	const handleSearchTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		onSearchTypeSelect(event.target.value);
	};
	const [t] = useTranslation();

	return (
		<div id='search-content-by-type' className='mb-3 search-options-container'>
			<div className='search-options-left'>
				<FormGroup check inline>
					<Input
						className='form-check-input'
						type='radio'
						onChange={handleSearchTypeSelect}
						id='search-type-radio-articles'
						name='inline-radios-search-type'
						checked={type === 'articles'}
						value='articles'
					/>
					<Label className='search-options-type-label' check htmlFor='search-type-radio-articles'>
						{t('articles')}
					</Label>
				</FormGroup>
				{featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]) && (
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={handleSearchTypeSelect}
							id='search-type-radio-videos'
							name='inline-radios-search-type'
							checked={type === 'videos'}
							value='videos'
						/>
						<Label className='search-options-type-label' check htmlFor='search-type-radio-videos'>
							{t('videos')}
						</Label>
					</FormGroup>
				)}
				<FormGroup check inline>
					<Input
						className='form-check-input'
						type='radio'
						onChange={handleSearchTypeSelect}
						id='search-type-radio-galleries'
						name='inline-radios-search-type'
						checked={type === 'galleries'}
						value='galleries'
					/>
					<Label className='search-options-type-label' check htmlFor='search-type-radio-galleries'>
						{t('galleries')}
					</Label>
				</FormGroup>
				{featuresService.areFeaturesEnabled([FeatureTypes.LIVE_BLOG]) && (
					<FormGroup check inline>
						<Input
							className='form-check-input'
							type='radio'
							onChange={handleSearchTypeSelect}
							id='search-type-radio-live-blogs'
							name='inline-radios-search-type'
							checked={type === `${ContentTypes.LIVE_BLOG}s`}
							value='live_blogs'
						/>
						<Label className='search-options-type-label' check htmlFor='search-type-radio-live-blogs'>
							{t('live_blogs')}
						</Label>
					</FormGroup>
				)}
			</div>

			<div className='search-options-right'>
				{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && (
					<>
						<Label className='fu-search-options-label'>{t('by_fans_united')}:</Label>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={handleSearchTypeSelect}
								id='search-type-radio-fu-polls'
								name='inline-radios-search-type'
								checked={type === FuContentTypes.POLL}
								value={FuContentTypes.POLL}
							/>
							<Label className='search-options-type-label' check htmlFor='search-type-radio-fu-polls'>
								{t('polls')}
							</Label>
						</FormGroup>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={handleSearchTypeSelect}
								id='search-type-radio-fu-match_quiz'
								name='inline-radios-search-type'
								checked={type === FuContentTypes.MATCH_QUIZ}
								value={FuContentTypes.MATCH_QUIZ}
							/>
							<Label className='search-options-type-label' check htmlFor='search-type-radio-fu-match_quiz'>
								{t('match_quiz')}
							</Label>
						</FormGroup>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={handleSearchTypeSelect}
								id='search-type-radio-fu-classic-quiz'
								name='inline-radios-search-type'
								checked={type === FuContentTypes.CLASSIC_QUIZ}
								value={FuContentTypes.CLASSIC_QUIZ}
							/>
							<Label className='search-options-type-label' check htmlFor='search-type-radio-fu-classic-quiz'>
								{t('classic_quiz')}
							</Label>
						</FormGroup>
					</>
				)}
			</div>
		</div>
	);
};

export default SearchContentByType;
