import React, { useEffect, useState } from 'react';
import HttpService from '../../../../../../services/rest/HttpService';
import { convertTeamIdsToMatchSearchUrls, markMatchModelAsSelected, responseToMatchModel } from './helpers/matches-container-helper';
import { Matches } from './matches';
import MatchModel from '../../../../../../models/v2/match/entity/match.model';

type Properties = {
	teamIds?: any[];
	isMulti: boolean;
	teamNames: string[];
	maxNotStartedMatches: number;
	maxFinishedMatches: number;
	maxInterruptedMatches: number;
	selectedMatchIds?: any;
	selectedMatchId?: any;
	onSelect: any;
	round?: any;
	stageId?: string;
	updateCheckAllMatchesState?: any;
	upcomingMatchesAreSelected?: any;
	finishedMatchesAreSelected?: any;
	hideMatchStatusTabs?: boolean;
	interruptedMatchesAreSelected?: any;
	t: any;
};

export const MatchesContainer: React.FunctionComponent<Properties> = (props) => {
	const [matchesFinished, setMatchesFinished] = useState<MatchModel[]>([]);
	const [matchesNotStarted, setMatchesNotStarted] = useState<MatchModel[]>([]);
	const [matchesInterrupted, setMatchesInterrupted] = useState<MatchModel[]>([]);
	const [selectedTeamNames, setSelectedTeamNames] = useState<string[]>([]);
	const [isContentLoading, setIsContentLoading] = useState(false);
	const [currentSelectedTeamsIds, setCurrentSelectedTeamsIds] = useState<string[]>([]);

	useEffect(() => {
		const { isMulti, selectedMatchId, selectedMatchIds, round } = props;

		if (round && round.round) {
			matchesByRound(selectedMatchId, selectedMatchIds, isMulti);
		}
	}, []);

	useEffect(() => {
		const { teamIds, teamNames, selectedMatchId, selectedMatchIds } = props;
		const resetToInitialState = () => {
			setMatchesFinished([]);
			setMatchesNotStarted([]);
			setMatchesInterrupted([]);
			setSelectedTeamNames([]);
			setIsContentLoading(false);
		};

		if (teamIds && JSON.stringify(currentSelectedTeamsIds) !== JSON.stringify(teamIds)) {
			setSelectedTeamNames(teamNames);
			setCurrentSelectedTeamsIds(teamIds);

			if (teamIds.length < 3 && teamIds.length > 0) {
				getMatches(convertTeamIdsToMatchSearchUrls(teamIds), selectedMatchId, selectedMatchIds, props.isMulti);
			}

			if (teamIds.length < 1) {
				resetToInitialState();
			}
		}
	}, [props.teamIds]);

	useEffect(() => {
		const { selectedMatchId, selectedMatchIds, isMulti } = props;

		if ((selectedMatchId && selectedMatchId > 0) || (selectedMatchIds && selectedMatchIds.length > 0)) {
			let finished = markMatchModelAsSelected(matchesFinished, selectedMatchId, selectedMatchIds, isMulti);
			let notStarted = markMatchModelAsSelected(matchesNotStarted, selectedMatchId, selectedMatchIds, isMulti);
			let interrupted = markMatchModelAsSelected(matchesInterrupted, selectedMatchId, selectedMatchIds, isMulti);

			setMatchesFinished(finished);
			setMatchesNotStarted(notStarted);
			setMatchesInterrupted(interrupted);
		}
	}, [props.selectedMatchId, props.selectedMatchIds, props.isMulti]);

	const getMatches = (urls: string[], selectedMatchId: number, selectedMatchIds: number[], isMulti: boolean) => {
		const { maxNotStartedMatches, maxFinishedMatches, maxInterruptedMatches, hideMatchStatusTabs } = props;
		setIsContentLoading(true);

		if (hideMatchStatusTabs) {
			HttpService.requestMultipleFootballRequests([urls[0], urls[2]])
				.then((response: any) => {
					let notStarted = response[0].data
						.reverse()
						.slice(0, maxNotStartedMatches)
						.map((item: any) => responseToMatchModel(item));

					let progress = response[1].data.map((item: any) => responseToMatchModel(item));

					notStarted = [...progress, ...notStarted];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					setMatchesNotStarted(notStarted);
					setIsContentLoading(false);
				})
				.catch((e: any) => {
					setIsContentLoading(false);

					return e;
				});
		} else {
			HttpService.requestMultipleFootballRequests([...urls])
				.then((response: any) => {
					let notStarted = response[0].data
						.reverse()
						.slice(0, maxNotStartedMatches)
						.map((item: any) => responseToMatchModel(item));
					let finished = response[1].data.slice(0, maxFinishedMatches).map((item: any) => responseToMatchModel(item));
					let progress = response[2].data.map((item: any) => responseToMatchModel(item));
					let interrupted = response[3].data.slice(0, maxInterruptedMatches).map((item: any) => responseToMatchModel(item));

					notStarted = [...progress, ...notStarted];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
					interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);

					setMatchesFinished(finished);
					setMatchesNotStarted(notStarted);
					setMatchesInterrupted(interrupted);
					setIsContentLoading(false);
				})
				.catch((e: any) => {
					setIsContentLoading(false);

					return e;
				});
		}
	};

	const matchesByRound = (selectedMatchId: number, selectedMatchIds: number[], isMulti: boolean) => {
		const { maxNotStartedMatches, maxFinishedMatches, maxInterruptedMatches } = props;
		setIsContentLoading(true);

		HttpService.getRoundEvents(props.stageId, props.round.round)
			.then((response: any) => {
				let notStarted = response.data
					.reverse()
					.slice(0, maxNotStartedMatches)
					.filter((item: any) => item.event_status.type === 'notstarted')
					.map((item: any) => responseToMatchModel(item));
				let finished = response.data
					.reverse()
					.slice(0, maxFinishedMatches)
					.filter((item: any) => item.event_status.type === 'finished')
					.map((item: any) => responseToMatchModel(item));
				let progress = response.data
					.filter((item: any) => item.event_status.type === 'inprogress')
					.map((item: any) => responseToMatchModel(item));
				let interrupted = response.data
					.reverse()
					.slice(0, maxInterruptedMatches)
					.filter((item: any) => item.event_status.type === 'interrupted')
					.map((item: any) => responseToMatchModel(item));

				notStarted = [...progress, ...notStarted];
				notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
				finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
				interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);

				setMatchesFinished(finished);
				setMatchesNotStarted(notStarted);
				setMatchesInterrupted(interrupted);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				setIsContentLoading(false);

				return e;
			});
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`} data-qa='matches-container'>
			<Matches
				selectedTeamNames={selectedTeamNames}
				t={props.t}
				hideMatchStatusTabs={props.hideMatchStatusTabs}
				isMulti={props.isMulti}
				onMatchSelect={props.onSelect}
				onMatchesSelect={props.onSelect}
				matchesFinished={matchesFinished}
				matchesNotStarted={matchesNotStarted}
				matchesInterrupted={matchesInterrupted}
				updateCheckAllMatchesState={props.updateCheckAllMatchesState}
				upcomingMatchesAreSelected={props.upcomingMatchesAreSelected}
				finishedMatchesAreSelected={props.finishedMatchesAreSelected}
				interruptedMatchesAreSelected={props.interruptedMatchesAreSelected}
			/>
		</div>
	);
};
