import { ListItemJson } from './ListItemJson';
import ListItem from './ListItem';
import ListItemMeta from './list-item-meta/ListItemMeta';
import Image from '../../image/Image';
import MainMedia from '../../mainmedia/MainMedia';

export default class ListItemBuilder {
	private json: ListItemJson;

	constructor(listItem?: ListItem | ListItemJson) {
		if (listItem && listItem instanceof ListItem) {
			this.json = listItem.toJSON();
		} else if (listItem) {
			this.json = listItem;
		} else {
			this.json = {} as ListItemJson;
		}
	}

	withData(data: any): ListItemBuilder {
		this.json.data = data;

		return this;
	}

	withMeta(meta: ListItemMeta): ListItemBuilder {
		this.json.meta = meta;

		return this;
	}

	withType(type: string): ListItemBuilder {
		this.json.type = type;

		return this;
	}

	withWeight(weight: number): ListItemBuilder {
		this.json.weight = weight;

		return this;
	}

	withAutomaticContent(value: boolean): ListItemBuilder {
		this.json.is_automatic_content = value;

		return this;
	}

	withFrom(from: string | null): ListItemBuilder {
		this.json.from = from;

		return this;
	}

	withTo(to: string | null): ListItemBuilder {
		this.json.to = to;

		return this;
	}

	getMainImage(): Image {
		let image: Image = {} as Image;

		if (this.json.data && this.json.data.mainMedia) {
			this.json.data.mainMedia.forEach((mainMedia: MainMedia) => {
				if (mainMedia.resourceSubtype === 'main_image') {
					image = Image.builder(mainMedia.data).build();
				}
			});
		}

		return image;
	}

	build(): ListItem {
		return ListItem.fromJSON(this.json);
	}
}
