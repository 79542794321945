import React from 'react';
import './status-pill.styles.scss';
import { fuStatuses } from '../../Shared/search-content-by-type/helpers/search-content-by-type-container.helper';
import { useTranslation } from 'react-i18next';

type StatusPillProps = {
	status: 'OPEN' | 'PENDING' | 'ACTIVE';
};

const StatusPill: React.FC<StatusPillProps> = ({ status }) => {
	const [t] = useTranslation();

	const getStatusStyle = () => {
		switch (status) {
			case fuStatuses.OPEN:
				return {
					background: '#B8D0FE',
					color: '#000',
				};
			case fuStatuses.PENDING:
				return {
					background: '#FFD6A5',
					color: '#000',
				};
			case fuStatuses.ACTIVE:
				return {
					background: '#D9F7BE',
					color: '#000',
				};
			default:
				return {};
		}
	};

	return (
		<div className='status-pill' style={getStatusStyle()}>
			{t(status)}
		</div>
	);
};

export default StatusPill;
