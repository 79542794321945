import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Badge } from 'reactstrap';
import {
	cssIconByContentType,
	extractCorrectImageUrl,
	returnCorrectUrlPathToContent,
} from '../../../Resources/Lists/ListsContent/list-content.helper';
import { capitalize } from '../../../../global-helpers/global.helpers';
import moment from 'moment';
import ListItem from '../../../../models/list/list-item/ListItem';
import ContentItemStatisticsComponent from '../../../Resources/Lists/ListsContent/subcomponents/content-item-statistics.component';
import { Link } from 'react-router-dom';
import { ContentTypes } from '../../../../constants/content-types';
import './style/search-options.scss';
import ListItemAddButtons from '../../Sidebar/lists/subcomponents/list-item-add-buttons.component';
import List from '../../../../models/list/List';
import { relatedTypesMap } from './helpers/search-content-by-type-container.helper';
import { EMBED_ICON } from '../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';

type Props = {
	t: any;
	match: any;
	options: any[];
	onRelatedContentSelect: (option: any) => void;
	listItems: ListItem[];
	listContent: boolean;
	list: List;
	toggleFirstLockPositionError: (toggleError: boolean) => any;
	showAddToTopToggle: boolean;
	type?: string;
};

const SearchOptions: React.FunctionComponent<RouteComponentProps<{}> & Props> = (props) => {
	const {
		match,
		onRelatedContentSelect,
		t,
		options,
		listItems,
		listContent,
		toggleFirstLockPositionError,
		list,
		showAddToTopToggle,
		type,
	} = props;

	if (options && options.length === 0) {
		return <div className='text-center text-muted p-3'> {t('no_data_found')}</div>;
	}
	const filterList = (options: any) => {
		if (options) {
			return type === relatedTypesMap.sports_connections
				? options.filter(
						(option: any) =>
							listItems &&
							!listItems.find(
								(el: any) => el.data && el.data.id.toString() === option.data.id.toString() && el.data.entity_type === option.data.entity_type,
							),
				  )
				: options.filter(
						(option: any) =>
							option.data.id !== match.params.id && listItems && !listItems.find((el: any) => el.data.id.toString() === option.data.id.toString()),
				  );
		}
	};
	return (
		<>
			{filterList(options).length === 0 ? (
				<div className='text-center pt-2'>
					<strong>{t('no_more_content_to_select')}</strong>
				</div>
			) : (
				<ul className='list-group'>
					{filterList(options).map((option: any) => {
						let createdByData = `${t('created_by')}: ${option.createdBy && option.createdBy.full_name}`;
						let connectionType = `${t('type')}: ${t(option.entityType)}`;
						return (
							<li key={`option-data-${option.data.id + Math.random()}`} className='list-group-item d-flex align-items-center'>
								<div className='mr-2'>
									<ListItemAddButtons
										t={t}
										currentContent={option}
										list={list}
										onListItemAdd={onRelatedContentSelect}
										toggleFirstLockPositionError={toggleFirstLockPositionError}
										showAddToTopToggle={showAddToTopToggle}
									/>
								</div>
								<div className={'image-wrapper mr-3'}>
									{option.type !== relatedTypesMap.tags + 's' && <img src={extractCorrectImageUrl(option)} className={'max-width-100 rounded'} />}
								</div>
								{listContent ? (
									<>
										{type === relatedTypesMap.sports_connections ? (
											<div className='d-flex flex-column mr-auto'>
												<label>
													<i
														className={`fa ${cssIconByContentType[type === relatedTypesMap.sports_connections ? type : option.type]} p-1`}
														title={capitalize(option.type)}
													/>
													{type === relatedTypesMap.sports_connections ? option.data.name : option.data.title}
												</label>
												{option.type === relatedTypesMap.tags
													? list.contentType !== 'editorial' && `${createdByData}`
													: list.contentType !== 'editorial' && `${connectionType}`}
											</div>
										) : (
											<div className='d-flex flex-column mr-auto'>
												<Link to={returnCorrectUrlPathToContent(option)} className='text-dark ml-1'>
													<i
														className={`fa ${cssIconByContentType[type === relatedTypesMap.sports_connections ? type : option.type]} p-1`}
														title={capitalize(option.type)}
													/>
													<strong>{type === relatedTypesMap.sports_connections ? option.data.name : option.data.title}</strong>
												</Link>
												{type !== relatedTypesMap.tags + 's' && type !== relatedTypesMap.sports_connections && type !== ContentTypes.LIVE_BLOG + 's' && (
													<div className='d-flex pl-1 pr-1'>
														<ContentItemStatisticsComponent t={t} value={option.data} style='pr-2 d-flex' />
													</div>
												)}
												{option.type === relatedTypesMap.tags
													? list.contentType !== 'editorial' && `${createdByData}`
													: list.contentType !== 'editorial' && `${connectionType}`}
											</div>
										)}
										{type !== relatedTypesMap.tags + 's' && type !== relatedTypesMap.sports_connections && (
											<small>{`${t('published_at')}: ${moment(option.publishedAt).format('DD MMMM YYYY, HH:mm')}`}</small>
										)}
									</>
								) : (
									<Link
										to={`/smp/${option.type.toLocaleLowerCase() === ContentTypes.GALLERY ? 'gallerie' : option.type.toLowerCase()}s/edit/${
											option.data.id
										}`}
										className='text-dark ml-1'
										style={{ pointerEvents: 'all' }}
									>
										{option.data && option.data.status === 'active' ? (
											<i className={`option-logo mr-2 fa-lg fa fa-check-circle text-success`} />
										) : (
											<i className={`option-logo mr-2 fa-lg fa fa-times-circle text-danger`} />
										)}
										{option.data &&
											option.data.main_media &&
											option.data.main_media.map((media: any) => {
												return type === ContentTypes.VIDEO + 's' && media.resource_type === 'embed' && EMBED_ICON;
											})}
										{option.data.title}
										{type !== relatedTypesMap.tags + 's' && type !== relatedTypesMap.sports_connections && (
											<div>
												<Badge className='mt-1 text-dark search-options-published-date'>
													{moment(option.data.published_at).format('DD MMM YYYY, HH:mm')}
												</Badge>
											</div>
										)}
									</Link>
								)}
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
};

export default withRouter(SearchOptions);
