import AutomaticListCriteriaBuilder from './AutomaticListCriteriaBuilder';
import { AutomaticListCriteriaJson } from './AutomaticListCriteriaJson';

export default class AutomaticListCriteria {
	readonly entityTypes: string[];
	// TODO add filters when BE is ready
	// readonly filters: any;

	private constructor(entityTypes: string[]) {
		this.entityTypes = entityTypes;
	}

	toJSON(): AutomaticListCriteriaJson {
		return {
			entityTypes: this.entityTypes,
		};
	}

	static fromJSON(json: AutomaticListCriteriaJson): AutomaticListCriteria {
		return new AutomaticListCriteria(json.entityTypes);
	}

	static builder(automaticListCriteria?: AutomaticListCriteria): AutomaticListCriteriaBuilder {
		return new AutomaticListCriteriaBuilder(automaticListCriteria);
	}
}
