import React from 'react';
import { PaginationProps } from '../../Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/properties/ImageListPaginateProps';
import ReactPaginate from 'react-paginate';
import PaginationMeta from '../../../../models/pagination/PaginationMeta';
import SearchOptions from './search-options';
import ListItem from '../../../../models/list/list-item/ListItem';
import Scrollbars from 'react-custom-scrollbars';
import List from '../../../../models/list/List';
import { relatedTypesMap } from './helpers/search-content-by-type-container.helper';

type Props = {
	t: any;
	value: string;
	options: any[];
	isLoading: boolean;
	currentPage: number;
	pagination: PaginationMeta;
	onRelatedContentSelect: (option: any) => void;
	requestContent: (selected: number, value: string) => void;
	listItems: ListItem[];
	listContent: boolean;
	list: List;
	toggleFirstLockPositionError: (toggleError: boolean) => any;
	showAddToTopToggle: boolean;
	type?: string;
};

const SearchOptionsContainer: React.FunctionComponent<Props> = (props) => {
	const {
		onRelatedContentSelect,
		t,
		options,
		isLoading,
		currentPage,
		pagination,
		value,
		requestContent,
		listItems,
		listContent,
		toggleFirstLockPositionError,
		list,
		type,
	} = props;
	if (options && options.length === 0) {
		if (type === relatedTypesMap.sports_connections) {
			return <div className='text-center text-muted p-3'> {t('search_for_sports_connections')}</div>;
		} else {
			return <div className='text-center text-muted p-3'> {t('no_data_found')}</div>;
		}
	}

	if (listContent) {
		return (
			<>
				<div className={`${isLoading ? 'loading-overlay' : 'mb-4'}`}>
					<SearchOptions
						listContent={listContent}
						onRelatedContentSelect={onRelatedContentSelect}
						listItems={listItems}
						t={t}
						options={options}
						list={list}
						toggleFirstLockPositionError={toggleFirstLockPositionError}
						showAddToTopToggle={props.showAddToTopToggle}
						type={type}
					/>
				</div>
				{type !== relatedTypesMap.sports_connections && (
					<ReactPaginate
						{...PaginationProps(t, currentPage)}
						pageCount={pagination.totalPages}
						forcePage={currentPage ? currentPage - 1 : 0}
						onPageChange={(data) => requestContent(data.selected + 1, value)}
					/>
				)}
			</>
		);
	}

	return (
		<>
			<div className={`${isLoading ? 'loading-overlay' : 'mb-4'}`}>
				<Scrollbars style={{ height: 300 }} className='top-shadow'>
					<SearchOptions
						listContent={listContent}
						onRelatedContentSelect={onRelatedContentSelect}
						listItems={listItems}
						t={t}
						options={options}
						list={list}
						toggleFirstLockPositionError={toggleFirstLockPositionError}
						showAddToTopToggle={props.showAddToTopToggle}
						type={type}
					/>
				</Scrollbars>
			</div>
			{type !== 'sports_connections' && (
				<ReactPaginate
					{...PaginationProps(t, currentPage)}
					pageCount={pagination.totalPages}
					forcePage={currentPage ? currentPage - 1 : 0}
					onPageChange={(data) => requestContent(data.selected + 1, value)}
				/>
			)}
		</>
	);
};

export default SearchOptionsContainer;
