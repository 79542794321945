import { PopularitySettingsJson } from './PopularitySettingsJson';
import PopularitySettings from './PopularitySettings';
import { PopularitySettingsRequestJson } from './PopularitySettingsRequestJson';

export default class PopularitySettingsBuilder {
	private json: PopularitySettingsJson;

	constructor(settingsConfig?: PopularitySettings | PopularitySettingsJson) {
		if (settingsConfig && settingsConfig instanceof PopularitySettings) {
			this.json = settingsConfig.toJSON();
		} else if (settingsConfig) {
			this.json = settingsConfig;
		} else {
			this.json = {} as PopularitySettingsJson;
		}
	}

	withTimeRange(timeRange: string): PopularitySettingsBuilder {
		this.json.timeRange = timeRange;

		return this;
	}

	withDataSource(dataSource: string): PopularitySettingsBuilder {
		this.json.dataSource = dataSource;

		return this;
	}

	withFrequency(frequency: string): PopularitySettingsBuilder {
		this.json.frequency = frequency;

		return this;
	}

	build(): PopularitySettings {
		return PopularitySettings.fromJSON(this.json);
	}

	equals(popularitySettings: PopularitySettings): boolean {
		if (popularitySettings && this.json) {
			const timeRangeEqual = this.json.timeRange === popularitySettings.timeRange;
			const dataSourceEqual = this.json.dataSource === popularitySettings.dataSource;
			const frequencyEqual = this.json.frequency === popularitySettings.frequency;

			return timeRangeEqual && frequencyEqual && dataSourceEqual;
		}

		return true;
	}

	toRequestJson(): PopularitySettingsRequestJson {
		let json = {} as PopularitySettingsRequestJson;
		json.time_range = this.json.timeRange;
		json.data_source = this.json.dataSource;
		json.update_frequency = this.json.frequency;

		return json;
	}
}
