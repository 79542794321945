import { ContentTypes } from '../../../../../constants/content-types';
import PaginationModel from '../../../../../models/v2/Pagination/PaginationModel';
import ImageModel from '../../../../../models/v2/image/image.model';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import Image from '../../../../../models/image/Image';

export const imageResourceType = 'image';
export const imageResourceSubType = 'main_image';
export const videoResourceType = 'video';
export const galleryResourceType = 'gallery';
export const videoEmbedResourceType = 'embed';
export const imagoResource = 'imago';
export const gettyResource = 'getty';
export const embedType = 'embed';

export enum MainMediaSelectOptionsValue {
	MEDIA_IMAGE = 'media-image',
	MEDIA_VIDEO = 'media-video',
	MEDIA_GALLERY = 'media-gallery',
	EMBED_VIDEO = 'embed-video',
}

export type MainMediaUpdateFunction = (mainMedia: MainMediaModel) => void;
export type AlreadyUploadedImagoImages = Record<string, MainMediaModel>[];
export type AlreadyUploadedGettyImages = Record<string, MainMediaModel>[];
export type MainMediaActionDispatchFunction = (mainMedia: MainMediaModel[]) => void;

export type ImagesRequestData = {
	images: ImageModel[];
	pagination: PaginationModel;
};

export type ImagesV1ModelRequestData = {
	images: Image[];
	pagination: PaginationModel;
};

export type MainMediaSelectMenuOptions = {
	value: string;
	label: string;
	subType: string | null;
	type: string;
};

export const imageAsSelectOption = (t: any): MainMediaSelectMenuOptions => ({
	value: MainMediaSelectOptionsValue.MEDIA_IMAGE,
	label: t('image'),
	subType: null,
	type: ContentTypes.IMAGE,
});

export const videoAsSelectOption = (t: any): MainMediaSelectMenuOptions => ({
	value: MainMediaSelectOptionsValue.MEDIA_VIDEO,
	label: t('video'),
	subType: null,
	type: ContentTypes.VIDEO,
});

export const galleryAsSelectOption = (t: any): MainMediaSelectMenuOptions => ({
	value: MainMediaSelectOptionsValue.MEDIA_GALLERY,
	label: t('gallery'),
	subType: null,
	type: ContentTypes.GALLERY,
});

export const embedVideoAsSelectOption = (t: any): MainMediaSelectMenuOptions => ({
	value: MainMediaSelectOptionsValue.EMBED_VIDEO,
	label: t('embed_video'),
	subType: ContentTypes.VIDEO,
	type: embedType,
});

export const mainMediaSelectOptions = (t: any): MainMediaSelectMenuOptions[] => {
	return [videoAsSelectOption(t), galleryAsSelectOption(t), embedVideoAsSelectOption(t)];
};

export const mainMediaLiveBlogSelectOptions = (t: any): MainMediaSelectMenuOptions[] => {
	return [
		imageAsSelectOption(t),
		videoAsSelectOption(t),
		galleryAsSelectOption(t),
		embedVideoAsSelectOption(t),
	];
};
