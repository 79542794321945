import React, { FunctionComponent, ChangeEvent, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { removeTextEmojis } from '../../helpers/slug.helper';
import { connect } from 'react-redux';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import ErrorHandler from '../../../../Blocky/partials/error/error-handler-component';

type Properties = {
	t: any;
	seoTitle: string;
	contentTitle: string;
	isDisabled: boolean;
	onSeoChange: (data: string, propertyName: ReduxURLsProps) => void;
};

const MAX_CHARACTER_LIMIT = 60;

const SeoTitleInput: FunctionComponent<Properties> = ({ t, seoTitle, contentTitle, isDisabled, onSeoChange }) => {
	const [isExceedCharacterLimit, setExceedCharacterLimit] = useState(false);
	const [hasTyped, setHasTyped] = useState(false);

	useEffect(() => {
		isDisabled && onSeoChange(removeTextEmojis(contentTitle), ReduxURLsProps.TITLE);
	}, [isDisabled, contentTitle]);

	const onSeoTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value || '';
		setHasTyped(true);
		onSeoChange(removeTextEmojis(inputText), ReduxURLsProps.TITLE);
	};

	useEffect(() => {
		const exceedsLimit = seoTitle.length > MAX_CHARACTER_LIMIT;
		setExceedCharacterLimit(exceedsLimit);
	}, [seoTitle]);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.TITLE}>{t('seo_title')}</Label>
					<DebounceInput
						id={DATA_QA_ATTRIBUTES.TITLE}
						disabled={isDisabled}
						debounceTimeout={300}
						type='text'
						className={`form-control ${isExceedCharacterLimit && hasTyped ? 'field-error mb-1' : ''}`}
						placeholder={t('seo_title')}
						value={seoTitle}
						onChange={onSeoTitleChange}
					/>
					{isExceedCharacterLimit && hasTyped && <ErrorHandler errorMessage='seo_title_error' />}
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentTitle: state[tempPropertyNaming][nestedTempPropertyNaming].title || '',
		seoTitle:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.title) || '',
	};
}

export default connect(mapStateToProps)(SeoTitleInput);
