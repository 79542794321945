import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { GenericOptionType } from '../../../../constants/select';

type Properties = {
	inputId: string;
	isClearable?: boolean;
	options: GenericOptionType<string>[];
	timeRange: string;
	onTimeRangeSelect: (value: string) => void;
};

const TimeRangeSelect: React.FunctionComponent<Properties> = (props) => {
	const { inputId, isClearable, options, timeRange, onTimeRangeSelect } = props;
	const [t] = useTranslation();

	const selectedValue = options.find((option) => option.value === timeRange);

	const onChange = (option: GenericOptionType<string> | null) => (option ? onTimeRangeSelect(option.value) : onTimeRangeSelect(''));

	return (
		<>
			<Select
				inputId={inputId}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				isClearable={isClearable}
				placeholder={t('select')}
				options={options}
				value={selectedValue}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				onChange={(selection) => onChange(selection as GenericOptionType<string> | null)}
				className={'mb-1'}
			/>
		</>
	);
};

export default TimeRangeSelect as React.ComponentType<Properties>;
