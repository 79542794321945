import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { DebounceInput } from 'react-debounce-input';
import EmojiContainer from '../../Shared/emoticons/emoji-container';
import './title.styles.scss';
import { displayMainMediaDescriptionLimit } from '../summary/summary-validation.helper';

type TitleProps = {
	t: any;
	onChange: any;
	value: string;
	disabled?: boolean;
	timeoutValue?: number;
	showValidationMessage?: boolean;
	dataQaProperty?: string;
	strapLineValue?: string;
	summaryValue?: string;
	tabId?: string;
	activeTab?: string;
	isMetaDataVersion?: boolean;
};

type State = {
	selectionEnd: number;
};

export class Title extends React.Component<TitleProps, State> {
	private inputRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			selectionEnd: 0,
		};
		this.inputRef = null;
	}

	setRef = (elem: any) => (this.inputRef = elem);

	onChangeText = (e: any) => this.props.onChange(e.target.value);

	setSelectionEnd = (end: number) => this.setState({ ...this.state, selectionEnd: end });

	onBlur = (e: any) => this.setSelectionEnd(e.target.selectionEnd);

	render() {
		const {
			onChange,
			t,
			value,
			disabled,
			timeoutValue,
			showValidationMessage,
			dataQaProperty,
			summaryValue,
			strapLineValue,
			tabId,
			activeTab,
			isMetaDataVersion = false,
		} = this.props;
		const { selectionEnd } = this.state;

		const showErrorValidationMessage = !isMetaDataVersion || (isMetaDataVersion && activeTab === tabId && showValidationMessage);
		const isEmptyTitle =
			(!value || value.trim().length === 0) && ((summaryValue && summaryValue.length > 0) || (strapLineValue && strapLineValue.length > 0));
		const isDefaultTitle = dataQaProperty === 'default-title' || dataQaProperty === 'default-name';
		const noValue = !value || value.trim().length === 0;

		const displayCorrectErrorMessages = () => {
			if (showErrorValidationMessage && !isDefaultTitle) {
				return <>{noValue && <ErrorAsync t={t} errorType='versions' />}</>;
			} else if (!isMetaDataVersion || isDefaultTitle) {
				return (
					<>
						<ErrorAsync t={t} errorType='title' />
						<ErrorAsync t={t} errorType='name' />
					</>
				);
			}
		};

		return (
			<>
				<FormGroup className='d-flex position-relative mb-0' data-qa={dataQaProperty || 'default-title'}>
					<DebounceInput
						type='text'
						inputRef={this.setRef}
						id={dataQaProperty || 'default-title'}
						value={value ? value : ''}
						onBlur={this.onBlur}
						onChange={this.onChangeText}
						debounceTimeout={timeoutValue}
						className={`form-control-lg form-control title-input ${value && value.length > 255 ? 'validation-border' : ''} ${
							isEmptyTitle && showErrorValidationMessage && !isDefaultTitle && 'validation-border'
						}`}
						placeholder={t('title_placeholder')}
						required
						disabled={!!disabled}
					/>
					<EmojiContainer
						t={t}
						setSelectionEnd={this.setSelectionEnd}
						inputRef={this.inputRef}
						inputValue={value ? value : ''}
						selectionEnd={selectionEnd}
						onChange={onChange}
						calledFrom='title'
						disabled={!!disabled}
					/>
				</FormGroup>
				<div className={`flex-row float-right font-weight-bold`}>
					<Label className={value && value.length > 255 ? 'limit-exceeded' : ''}>{displayMainMediaDescriptionLimit(value)}</Label>
				</div>
				{isEmptyTitle && showErrorValidationMessage && !isDefaultTitle && (
					<Label className='custom-version-label' htmlFor={`version-title-error-message-${dataQaProperty}`}>
						{t('title_version_mandatory')}
					</Label>
				)}
				{displayCorrectErrorMessages()}
			</>
		);
	}
}
