import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamH2hMatchJson } from './football-team-h2h-match.json';
import FootballTeamH2hMatchBuilder from './football-team-h2h-match.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';

export default class FootballTeamH2hMatchWidgetModel {
	readonly team1: TeamModel;
	readonly team2: TeamModel;
	readonly team1TournamentSeason: EntitySeasonsSelectModel;
	readonly team2TournamentSeason: EntitySeasonsSelectModel;
	readonly stats: string[];
	readonly match: MatchModel;
	readonly displayOdds: boolean = false;
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = true;

	private constructor(
		team1: TeamModel,
		team2: TeamModel,
		team1TournamentSeason: EntitySeasonsSelectModel,
		team2TournamentSeason: EntitySeasonsSelectModel,
		stats: string[],
		match: MatchModel,
		displayOdds: boolean,
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean = true,
	) {
		this.team1 = team1;
		this.team2 = team2;
		this.team1TournamentSeason = team1TournamentSeason;
		this.team2TournamentSeason = team2TournamentSeason;
		this.stats = stats;
		this.match = match;
		this.displayOdds = displayOdds;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): FootballTeamH2hMatchJson {
		return {
			team1: this.team1,
			team2: this.team2,
			team1TournamentSeason: this.team1TournamentSeason,
			team2TournamentSeason: this.team2TournamentSeason,
			stats: this.stats,
			match: this.match,
			displayOdds: this.displayOdds,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: FootballTeamH2hMatchJson): FootballTeamH2hMatchWidgetModel {
		return new FootballTeamH2hMatchWidgetModel(
			json.team1,
			json.team2,
			json.team1TournamentSeason,
			json.team2TournamentSeason,
			json.stats,
			json.match,
			json.displayOdds,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(model?: FootballTeamH2hMatchWidgetModel): FootballTeamH2hMatchBuilder {
		return new FootballTeamH2hMatchBuilder(model);
	}
}
