export const errorsMap = (errorMessage, sources) => {
	let errors = {
		must_be_a_string: 'must_be_a_string',
		name: 'must_be_a_string',
		'may_not_be_greater_than_|max_30|_characters': 'may_not_be_greater_than_|max_30|_characters',
		has_already_been_taken: 'has_already_been_taken',
		format_is_invalid: 'format_is_invalid',
		must_be_an_array: 'must_be_an_array',
		must_be_a_valid_JSON_string: 'must_be_a_valid_JSON_string',
		external_url: 'format_is_invalid',
		canonical_url: 'format_is_invalid',
		invalid_credentials: 'invalid_credentials',
		invalid_request: 'invalid_request',
		'must_be_at_least_|min_8|_characters': 'must_be_at_least_|min_8|_characters',
		field_is_required: 'field_is_required',
		selected_is_invalid: 'selected_is_invalid',
		'main_media.0.provider': 'field_is_required',
		'main_media.1.provider': 'field_is_required',
		'main_media.2.provider': 'field_is_required',
		'may_not_be_greater_than_|max_255|_characters': 'may_not_be_greater_than_|max_255|_characters',
		'may_not_be_greater_than_|max_500|_characters': 'may_not_be_greater_than_|max_500|_characters',
		'field_is_required_when_none_of_|value_:value|s_are_present': 'field_is_required',
		'The location field is required.': 'location_field_is_required',
		'The location format is invalid.': 'location_format_is_invalid',
		'The name field is required.': 'name_is_required',
		'Cannot resolve location to a valid source': 'cannot_resolve_location',
		'video_files.0.url': 'field_is_required',
		whait_for_the_data: 'whait_for_the_data',
		// automatic_content_mode validation
		'Each item in versions must have both id and title properties.': 'versions_must_have_id_and_title_properties',
	};
	errors[`The ${sources}location field is required.`] = 'location_field_is_required';
	errors[`The ${sources}name field is required.`] = 'name_is_required';
	errors['video_files.0.url'] = 'field_is_required';

	if (errorMessage['video_files.0.url']) {
		return errors[errorMessage['video_files.0.url']];
	}

	return errors[errorMessage];
};
