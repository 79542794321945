import React, { FunctionComponent } from 'react';
import ListModel from '../../../../../../models/v2/list/list';
import { connect } from 'react-redux';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import * as ReactSortableHOC from 'react-sortable-hoc';
import ListContentSortableItem from './sortable-item';
import { updateListInRedux } from '../../../../../../store/action-creators/ListActionCreators';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { isListEmpty } from '../../helpers/general.helper';
import FuListContentSortableItem from './fu-sortable-item';

type Properties = {
	t: any;
	selectedList: ListModel | null;
	updateListInRedux: (list: ListModel) => void;
};

const SortableItem = ReactSortableHOC.SortableElement(ListContentSortableItem);
const FuSortableItem = ReactSortableHOC.SortableElement(FuListContentSortableItem);

const ListContentSortableList: FunctionComponent<Properties> = ({ t, selectedList, updateListInRedux }) => {
	if (!selectedList) return null;

	const onDeleteListItem = (itemType: string, itemId: string) => {
		const allItems = selectedList && selectedList.items && selectedList.items.length > 0 ? [...selectedList.items] : [];
		const itemIndexForRemoving = allItems.findIndex((listItem: ListItemModel) => listItem.data.id === itemId && listItem.type === itemType);
		itemIndexForRemoving >= 0 && allItems.splice(itemIndexForRemoving, 1);
		updateListInRedux({ ...selectedList, items: allItems });
	};

	return (
		<ul className='list-group mb-2' id={DATA_QA_ATTRIBUTES.LIST_ITEMS_CONTAINER}>
			{selectedList &&
				selectedList.items.map((item: ListItemModel, index: number) =>
					item.type === 'FANS_UNITED' ? (
						<FuSortableItem
							disabled={typeof item.lockedPosition === 'number'}
							selectedListItem={item}
							onDelete={onDeleteListItem}
							key={`key-sortable-${item.data.id}-${item.data.title}`}
							itemIndex={index}
							index={index}
						/>
					) : (
						<SortableItem
							disabled={typeof item.lockedPosition === 'number'}
							selectedListItem={item}
							onDelete={onDeleteListItem}
							t={t}
							key={`key-sortable-${item.data.id}-${item.data.title}`}
							itemIndex={index}
							index={index}
						/>
					),
				)}
		</ul>
	);
};

function mapStateToProps(state: any) {
	return {
		selectedList: (!isListEmpty(state.list.list) && state.list.list) || null,
	};
}

function mapDispatchToProps(dispatch: Function) {
	return {
		updateListInRedux: (list: ListModel) => dispatch(updateListInRedux(list)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContentSortableList);
