import React, { ChangeEvent } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/ListEditProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import List from '../../../models/list/List';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import {
	LIST_ENTITY_RECEIVED,
	LIST_ENTITY_REQUEST,
	LIST_ENTITY_UPDATE,
	LIST_ENTITY_UPDATE_FAILED,
	LIST_ENTITY_UPDATE_SUCCESS,
	returnObjectForListEntityRequest,
	returnObjectForListEntityUpdate,
	returnObjectForListItemsDeleteRequest,
} from '../../../store/action-creators/ListActionCreators';
import ListConfiguration from '../../../models/list/configuration/ListConfiguration';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { actionService, featuresService, sportsConnectionsService } from '../../../App';
import ErrorAsync from '../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import GeneralContentTypeAttribute from '../../Partials/Sidebar/general-content-attribute-custom/general-content-attribute-custom';
import { ContentTypes, ListContentTypes } from '../../../constants/content-types';
import { GeneralContentCustomModel } from '../../Partials/Sidebar/general-content-attribute-custom/models/general-content-attribute-custom.model';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import DeleteListItemsModal from './delete-items-modal/delete-items-modal.component';
import ListItem from '../../../models/list/list-item/ListItem';
import {
	getListTypeContentTranslated,
	isAutomatedLists,
	lockedItems,
	remapListToFitOptions,
	setListLockedPositions,
} from './helpers/list.helper';
import { customOption } from './helpers/custom-option';
import Select from 'react-select';
import { itemsToOptions } from '../../Partials/Shared/react-select-helpers/react-select.helpers';
import { Subscription } from 'rxjs';
import CategorySelectContainer from '../../Partials/Sidebar/GeneralContentAttributes/subcomponents/CategorySelect/CategorySelectContainer';
import Category from '../../../models/category/Category';
import Related from '../../../models/related/Related';
import FootballConnectionsContainer from '../../Partials/Sidebar/tags/subcomponents/football-connections/football-connections-container';
import SportsTypesModel from '../../../models/v2/sports-types/sports-types.model';
import { SportsTypeSelect } from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-type-select.component';
import { SportsConnectionsContainer } from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections-container';
import {
	filterFootballRelated,
	filterSportsRelated,
	removeDuplicateItems,
} from '../../Partials/Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import { AutomaticContentSelect, EditorialTypeItemsMultiselect, FrequencySelect, TimeRangeSelect } from './subcomponents';
import {
	automaticContentSelectOptions,
	editorialTypesSelectOptions,
	frequencySelectOptions,
	timeRangeSelectOptions,
} from '../../../constants/automatic-lists-constants';
import AutomaticListCriteria from '../../../models/list/criteria/AutomaticListCriteria';
import PopularitySettings from '../../../models/list/popularity-settings/PopularitySettings';

class ListEdit extends React.Component<Properties, State> {
	private actionServiceSubject: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			list: props.list,
			isContentLoading: false,
			isSidebarInEdit: false,
			showEditItemsModal: false,
			contentType: getListTypeContentTranslated(),
			sportsType: featuresService.areFeaturesEnabled([FeatureTypes.FOOTBALL, FeatureTypes.FOOTBALL_CONNECTIONS])
				? this.props.sports[0]
				: ({} as SportsTypesModel),
			footballRelated: [],
			sportsRelated: [],
			openSidebarSettingsModalFlag: false,
		};

		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => {
			if (action === LIST_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === LIST_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	//TODO remove related filtration logic when the football data is migrated to sports-search-api

	componentDidMount(): void {
		this.initPageTitle();
		this.props.getList(this.props.match.params.id, this.props.project);
		appendBeforeUnloadResetTempSidebar(ContentTypes.LIST);
		this.setState({
			footballRelated:
				this.props.list.configuration && this.props.list.configuration.sports
					? filterFootballRelated(this.props.list.configuration.sports)
					: [],
			sportsRelated:
				this.props.list.configuration && this.props.list.configuration.sports ? filterSportsRelated(this.props.list.configuration.sports) : [],
		});
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (!List.builder(prevProps.list).equals(this.props.list)) {
			this.setState({
				...this.state,
				list: this.props.list,
				footballRelated:
					this.props.list.configuration && this.props.list.configuration.sports
						? filterFootballRelated(this.props.list.configuration.sports)
						: [],
				sportsRelated:
					this.props.list.configuration && this.props.list.configuration.sports ? filterSportsRelated(this.props.list.configuration.sports) : [],
			});
		}

		if (prevProps !== this.props) {
			this.setState({
				contentType: getListTypeContentTranslated(),
			});
		}
	}

	componentWillUnmount() {
		this.actionServiceSubject.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('list_edit');
	}

	onTitleChange(title: string) {
		const list = List.builder(this.state.list).withTitle(title).build();
		this.setState({ ...this.state, list });
	}

	onSlugChange(event: any) {
		const list = List.builder(this.state.list).withSlug(event.target.value).build();
		this.setState({ ...this.state, list });
	}

	onMinItemsChange(event: any) {
		const configuration = ListConfiguration.builder(this.state.list.configuration).withMinItems(event.target.value).build();

		const list = List.builder(this.state.list).withConfiguration(configuration).build();
		this.setState({ ...this.state, list });
	}

	onMaxItemsChange(event: any) {
		const configuration = ListConfiguration.builder(this.state.list.configuration).withMaxItems(event.target.value).build();

		const list = List.builder(this.state.list).withConfiguration(configuration).build();
		this.setState((state) => {
			return { ...state, list: list };
		});
	}

	onEditorialItemsChange(event: ChangeEvent<HTMLInputElement>) {
		this.setState({
			list: List.builder(this.state.list).withAutomaticContentThreshold(event.target.value).build(),
		});
	}

	onLockItemsChange = (selections: any) => {
		const positions = setListLockedPositions(selections, this.state.list);
		const list = List.builder(this.state.list).withLockedPositions(positions).build();
		this.setState({ ...this.state, list });
	};

	onListItemsUpdate = (listItems: ListItem[]) => {
		const list = List.builder(this.state.list).withItems(listItems).build();
		this.setState((state) => {
			this.onSubmit(list);
			return { ...state, list: list };
		});
		this.props.deleteListItems();
	};

	onSubmit(list: List) {
		if (list.contentType === ListContentTypes.SCHEDULED) {
			this.props.updateList(list, this.props.project);
		} else {
			list && list.items.length > +list.configuration.maxItems && this.toggleListItemsModal(true);
			list && list.items.length <= +list.configuration.maxItems && this.props.updateList(list, this.props.project);
		}
	}

	blockIfNotPositive = (value: string) => {
		if (value === '') {
			return true;
		}

		if (value && value.toString().match(/[-]/g)) {
			return true;
		}

		return false;
	};

	onGeneralContentChange(data: GeneralContentCustomModel) {
		let list = List.builder(this.state.list)
			.withType(data.type ? data.type : '')
			.withStatus(data.status)
			.build();
		this.setState({ ...this.state, list });
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.LIST);
		this.setState(newState);
	};

	toggleListItemsModal = (isOpen: boolean) => {
		this.setState((state) => {
			return { ...state, showEditItemsModal: isOpen };
		});
	};

	onCategoryChange = (category: any) => {
		const { list } = this.state;
		const listConfig = ListConfiguration.builder(list.configuration).withCategory(category).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	//TODO unify onSportsDataChange when the football data is migrated to sports-search-api
	onFootballConnectionsChange = (football: Related[]) => {
		const { list } = this.state;
		const footballRelatedCopy = football.concat(this.state.sportsRelated);
		this.setState({ footballRelated: football });
		const listConfig = ListConfiguration.builder(list.configuration).withSports(footballRelatedCopy).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	onSportsConnectionsChange = (related: Related[]) => {
		const { list, footballRelated, sportsRelated, sportsType } = this.state;

		const tempData = sportsRelated.filter((element: Related) => element.data.sport !== sportsType.sport);
		const relatedData = related.concat(tempData);

		const sportsRelatedCopy = relatedData.concat(footballRelated);
		this.setState({ sportsRelated: removeDuplicateItems(sportsRelatedCopy) });
		const listConfig = ListConfiguration.builder(list.configuration).withSports(removeDuplicateItems(sportsRelatedCopy)).build();
		this.setState({
			list: List.builder(list).withConfiguration(listConfig).build(),
		});
	};

	onSportsTypesSelect = (type: any) => {
		this.setState({ ...this.state, sportsType: type });
	};

	onEditorialTypesSelect = (editorialTypes: string[]) => {
		const criteria = AutomaticListCriteria.builder(this.state.list.criteria).withEditorialTypes(editorialTypes).build();

		this.setState({
			list: List.builder(this.state.list).withCriteria(criteria).build(),
		});
	};

	onAutomaticContentModeSelect = (automaticContentMode: string) => {
		this.setState({
			list: List.builder(this.state.list).withAutomaticContentMode(automaticContentMode).build(),
		});
	};

	onTimeRangeSelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withTimeRange(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	onDataSourceSelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withDataSource(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	onFrequencySelect = (value: string) => {
		const popularitySettings = PopularitySettings.builder(this.state.list.popularitySettings).withFrequency(value).build();

		this.setState({
			list: List.builder(this.state.list).withPopularitySettings(popularitySettings).build(),
		});
	};

	render() {
		const { t, types, statuses, adminCategories, allCategories, project, sports } = this.props;
		const {
			list,
			isSidebarInEdit,
			showEditItemsModal,
			contentType,
			sportsType,
			footballRelated,
			sportsRelated,
			openSidebarSettingsModalFlag,
		} = this.state;

		const listItems = list ? itemsToOptions(remapListToFitOptions(list.items, t), true, t, '') : [];
		const lockedItemsOptions = itemsToOptions(remapListToFitOptions(lockedItems(list), t), true, t, '');
		const sidebarComponentsMap = {
			general: (
				<GeneralContentTypeAttribute
					onChange={this.onGeneralContentChange.bind(this)}
					value={{ type: list.type, status: list.status }}
					contentType={ContentTypes.LIST}
					isNewContent={false}
				/>
			),
		};
		const shouldShowSidebar = (types && types.length > 0) || (statuses && statuses.length > 0);
		const style = shouldShowSidebar ? '8' : '12';

		const overRideValidation = isAutomatedLists()
			? (list.configuration && !list.configuration.maxItems) ||
			  (list.configuration && !list.configuration.minItems) ||
			  !list.automaticContentThreshold ||
			  !list.title ||
			  !list.automaticContentMode ||
			  (list.automaticContentMode !== 'most_popular' && !list.criteria.entityTypes) ||
			  (list.automaticContentMode !== 'most_popular' && list.criteria.entityTypes && !list.criteria.entityTypes.length) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.frequency) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.timeRange)
			: list.configuration && (this.blockIfNotPositive(list.configuration.maxItems) || this.blockIfNotPositive(list.configuration.minItems));
		const releaseActionValidation = isAutomatedLists()
			? (list.configuration && !list.configuration.maxItems) ||
			  (list.configuration && !list.configuration.minItems) ||
			  !list.automaticContentThreshold ||
			  !list.title ||
			  !list.automaticContentMode ||
			  (list.automaticContentMode !== 'most_popular' && !list.criteria.entityTypes) ||
			  (list.automaticContentMode !== 'most_popular' && list.criteria.entityTypes && !list.criteria.entityTypes.length) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.frequency) ||
			  (list.automaticContentMode === 'most_popular' && !list.popularitySettings.timeRange)
			: list.configuration && (this.blockIfNotPositive(list.configuration.maxItems) || this.blockIfNotPositive(list.configuration.minItems));

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col={style} lg={style} md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0 text-truncate'}>
										{t('edit')}: {this.props.list ? this.props.list.title : ''}
									</span>
									<BlockableButtonWrapper
										blockOnActions={[LIST_ENTITY_UPDATE]}
										blockOverride={overRideValidation}
										releaseOnActions={[LIST_ENTITY_UPDATE_SUCCESS, LIST_ENTITY_UPDATE_FAILED] || releaseActionValidation}
									>
										<Button
											size='sm'
											color='primary'
											id='list-edit-save-top'
											className={'ml-auto text-nowrap'}
											onClick={() => this.onSubmit(this.state.list)}
										>
											<i className='fa fa-floppy-o'></i> {t('save_list')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label className='font-weight-bold' htmlFor='content-type'>
												{t('content_type')}
											</Label>
											<div className={`tip-container p-3 mb-3 my-2`}>
												<div className='tip-message pb-1'>
													<strong className='mt-1 info '>{t(contentType)}</strong>
												</div>
											</div>
										</Col>
										<Col xs='12'>
											<Label htmlFor='title'>
												{t('title')} <span className='text-danger'>*</span>
											</Label>
											<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.list ? this.state.list.title : ''} />
										</Col>
									</Row>
									<Row className={'mb-3'}>
										<Col xs='12'>
											<Label htmlFor='slug'>{t('slug')}</Label>
											<Input
												placeholder={t('slug')}
												value={this.state.list && this.state.list.slug !== null ? this.state.list.slug : ''}
												onChange={this.onSlugChange.bind(this)}
											/>
											<ErrorAsync t={t} errorType='slug' />
										</Col>
									</Row>
									{list.contentType !== ListContentTypes.SCHEDULED && (
										<>
											<Row className={'mb-3'}>
												<Col xs='6'>
													<Label htmlFor='title'>
														{t('min_items')} {isAutomatedLists() && <span className='text-danger'>*</span>}
													</Label>
													<Input
														type={'number'}
														min='0'
														value={this.state.list && this.state.list.configuration ? this.state.list.configuration.minItems : '0'}
														placeholder={t('min_items_placeholder')}
														onChange={this.onMinItemsChange.bind(this)}
													/>
													{list.configuration && this.blockIfNotPositive(list.configuration.minItems) && !isAutomatedLists() && (
														<div className='mt-1 alert alert-danger'>{t('positive_value_required')}</div>
													)}
												</Col>
												<Col xs='6'>
													<Label htmlFor='title'>
														{t('max_items')} {isAutomatedLists() && <span className='text-danger'>*</span>}
													</Label>
													<Input
														type={'number'}
														min='0'
														value={this.state.list && this.state.list.configuration ? this.state.list.configuration.maxItems : '0'}
														placeholder={t('max_items_placeholder')}
														onChange={this.onMaxItemsChange.bind(this)}
													/>
													{list.configuration && this.blockIfNotPositive(list.configuration.maxItems) && !isAutomatedLists() && (
														<div className='mt-1 alert alert-danger'>{t('positive_value_required')}</div>
													)}
												</Col>
											</Row>
											<Row className={'mb-3'}>
												{list.items && list.items.length > 0 && (
													<Col md='12'>
														<Label htmlFor='lock-items-select'>{t('lock_positions')}</Label>
														<Select
															inputId='lock-items-select'
															formatOptionLabel={customOption}
															isMulti
															value={lockedItemsOptions}
															onChange={this.onLockItemsChange}
															options={listItems}
														/>
													</Col>
												)}
											</Row>
										</>
									)}

									{list.contentType !== 'metadata' && (
										<Row>
											<Col xs='6'>
												<Label htmlFor='content-main-category-select'>{t('main_category')}</Label>
												<CategorySelectContainer
													inputId='content-main-category-select'
													t={t}
													isClearable={true}
													categories={allCategories}
													adminCategories={adminCategories}
													selectedCategory={list.configuration && list.configuration.category ? list.configuration.category : Category.builder().build()}
													onCategorySelect={this.onCategoryChange}
												/>
											</Col>
											<Col xs='6'>
												<SportsTypeSelect sports={sports} t={t} selectedSportsType={sportsType} onSportsTypeSelect={this.onSportsTypesSelect} />
											</Col>
											{sportsType && sportsConnectionsService.isFootballSelected(sportsType.sport) ? (
												<Col md='6'>
													<Label htmlFor='tags'>{t('football_connections')}</Label>
													<FootballConnectionsContainer
														related={filterFootballRelated(footballRelated)}
														onSelect={this.onFootballConnectionsChange}
														footballApiUrl={project.footballApiUrl}
														allowMoreFootballConnections={true}
														withSeason={false}
														inputId={'football-connections-drop-down'}
														t={t}
														contentLanguage={list.language}
													/>
												</Col>
											) : (
												sportsType.sport && (
													<Col md='6'>
														<Label htmlFor='sports-connections'>{t('sports_connections')}</Label>
														<SportsConnectionsContainer
															related={sportsRelated}
															onSportsConnectionSelect={this.onSportsConnectionsChange}
															contentLanguage={list.language}
															t={t}
															sportType={sportsType.sport}
															sportsEntities={sportsType.entity_types}
															allowMoreEntityTypes={true}
														/>
													</Col>
												)
											)}
										</Row>
									)}
									{isAutomatedLists() && (
										<Row className={'mb-3'}>
											<Col xs='6'>
												<Label htmlFor='title'>
													{t('number_of_automatic_items')} <span className='text-danger'>*</span>
												</Label>
												<Input
													type={'number'}
													min='0'
													value={this.state.list ? this.state.list.automaticContentThreshold : '0'}
													placeholder={t('number_of_automatic_items_placeholder')}
													onChange={this.onEditorialItemsChange.bind(this)}
												/>
											</Col>
											<Col xs='6'>
												<Label htmlFor='type-of-automatic-items-multiselect'>{t('type_of_automatic_items')}  {list.automaticContentMode === 'most_popular' ? '' : <span className='text-danger'>*</span>}</Label>
												<EditorialTypeItemsMultiselect
													inputId='type-of-automatic-items-multiselect'
													isClearable
													options={editorialTypesSelectOptions}
													editorialTypes={this.state.list.criteria ? this.state.list.criteria.entityTypes : []}
													onEditorialTypesSelect={this.onEditorialTypesSelect}
												/>
											</Col>
										</Row>
									)}
									{isAutomatedLists() && (
										<>
											<Row className={'mt-3'}>
												<Col xs='6'>
													<Label htmlFor='automatic-content-select'>
														{t('automatic_content')} <span className='text-danger'>*</span>
													</Label>
													<AutomaticContentSelect
														inputId='automatic-content-select'
														isClearable
														options={automaticContentSelectOptions}
														automaticContentMode={this.state.list.automaticContentMode}
														onDataSourceSelect={this.onDataSourceSelect}
														onAutomaticContentModeSelect={this.onAutomaticContentModeSelect}
													/>
												</Col>
											</Row>
											{this.state.list.automaticContentMode === 'most_popular' && (
												<Row className={'mt-3'}>
													<Col xs='6'>
														<Label htmlFor='time-range-select'>
															{t('time_range')} <span className='text-danger'>*</span>
														</Label>
														<TimeRangeSelect
															inputId='time-range-select'
															isClearable
															options={timeRangeSelectOptions}
															timeRange={this.state.list.popularitySettings.timeRange}
															onTimeRangeSelect={this.onTimeRangeSelect}
														/>
													</Col>
													<Col xs='6'>
														<Label htmlFor='frequency-select'>
															{t('update_frequency')} <span className='text-danger'>*</span>
														</Label>
														<FrequencySelect
															inputId='frequency-select'
															isClearable
															options={frequencySelectOptions}
															frequency={this.state.list.popularitySettings.frequency}
															onFrequencySelect={this.onFrequencySelect}
														/>
													</Col>
												</Row>
											)}
										</>
									)}
									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[LIST_ENTITY_UPDATE]}
												blockOverride={overRideValidation}
												releaseOnActions={[LIST_ENTITY_UPDATE_SUCCESS, LIST_ENTITY_UPDATE_FAILED] || releaseActionValidation}
											>
												<Button
													color={'primary'}
													id='list-edit-save-bottom'
													className={'text-uppercase font-weight-bold'}
													onClick={() => this.onSubmit(this.state.list)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_list')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					{shouldShowSidebar && (
						<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
							<SidebarCollapsibleElements
								isSidebarInEdit={isSidebarInEdit}
								onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.LIST)}
								onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
								contentType={ContentTypes.LIST}
								sidebarComponentsMap={sidebarComponentsMap}
								t={t}
								types={types[`${ContentTypes.LIST}Types`]}
							/>
						</Col>
					)}
				</Row>
				{list && list.items && showEditItemsModal && (
					<DeleteListItemsModal
						t={t}
						open={showEditItemsModal}
						onClose={() => this.toggleListItemsModal(false)}
						list={list}
						onListItemsUpdate={this.onListItemsUpdate}
					/>
				)}
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.LIST, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.LIST, defaultTempSidebarFromApiResponse[ContentTypes.LIST].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		list: state.list.list,
		project: state.project.currentProject,
		profile: state.profile.profile,
		types: state.types.listTypes,
		statuses: state.statuses.listStatuses,
		allCategories: state.category.allCategories,
		adminCategories: state.category.adminCategories,
		currentProject: state.project.currentProject,
		sports: state.sports.sportsTypes,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateList: (list: List, project: Project) => dispatch(returnObjectForListEntityUpdate(list, project)),
		getList: (id: string, project: Project) => dispatch(returnObjectForListEntityRequest(id, project)),
		deleteListItems: () => dispatch(returnObjectForListItemsDeleteRequest()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(ListEdit) as React.ComponentType;
