import React, { FunctionComponent, useEffect, useState } from 'react';
import EventsList from './components/events-list/events-list';
import EventsPreview from './components/events-preview/events-preview';
import { DATA_QA } from './helpers/data-qa-attributes';
import './styles/Page.scss';
import ConfigureDataSection from './components/configure-date/configure-date';
import RoundedBlueButton from '../../Partials/design-components/buttons/rounded-blue';
import MultisportHttpService from '../../../services/rest/multisport-http-service';
import { responseToMultisportModel } from './models/sport.mapper';
import MultisportModel from './models/sport.model';
import RemovePreviewEntities from './components/modals/remove-preview-entity';
import SavePreviewEntities from './components/modals/save-preview-entities';
import { connect, useSelector } from 'react-redux';
import {
	loadMultisportEntities,
	pushDataToModal,
	resetMultisportData,
	setMultisportConfigurationDate,
	setMultisportConfigurationCompetitionList,
} from '../../../store/action-creators/multisport-action-creator';
import { setTopEventSectionAsFirstElement } from './helpers/utils';
import { MultisportModalsType } from './components/modals/constants';
import { useTranslation } from 'react-i18next';
import { isDateObjValid } from '../../../global-helpers/global-dates.helper';
import { toast } from 'react-toastify';
import { SelectOption } from '../../Partials/design-components/select/select';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import { AppState } from '../../../store/store';

type Props = {
	loadMultisportEntities: (data: MultisportModel[]) => void;
	previewData: MultisportModel[];
	displaySaveModal: (changedConfigDate?: Date, competitionList?: string) => void;
	setConfigurationDate: (date: Date) => void;
	resetMultisportDataRedux: () => void;
	configurationCompetitionList: string;
	setConfigurationCompetitionList: (competitionList: string) => void;
};

const MultisportContainer: FunctionComponent<Props> = ({
	loadMultisportEntities,
	previewData,
	displaySaveModal,
	setConfigurationDate,
	resetMultisportDataRedux,
	configurationCompetitionList,
	setConfigurationCompetitionList,
}) => {
	const featureConfigData: Record<string, any> = featuresService.checkFeatureDataField(FeatureTypes.MULTISPORT_WIDGET_CONFIGURATION);
	const competitionListOptionsFromFeature: string[] = featureConfigData.competitionList || [];
	const mappedCompetitionListData: SelectOption[] = Array.isArray(competitionListOptionsFromFeature)
		? competitionListOptionsFromFeature.map((item: Record<string, any>) => {
				return { name: item.label, value: item.code };
		  })
		: [];
	const currentProject = useSelector((state: AppState) => state.project.currentProject);

	const { t } = useTranslation();
	const [configurationDateAsObj, setConfigurationDateAsObj] = useState(new Date());
	const [isPreviewEventsLoading, setPreviewEventsLoading] = useState<boolean>(false);
	const [initialFetchedData, setInitialFetchedData] = useState<MultisportModel[]>([]);
	const areInitialFetchedDataAndPreviewDataEqual = JSON.stringify(initialFetchedData) === JSON.stringify(previewData); //_.isEqual not working here :/
	const mappedSelectedCompetitionList: SelectOption = { name: configurationCompetitionList, value: configurationCompetitionList };
	const [initialCompetitionList, setInitialCompetitionList] = useState<string>(configurationCompetitionList);

	useEffect(() => {
		if (mappedCompetitionListData.length > 0) {
			setConfigurationCompetitionList(mappedCompetitionListData[0].value);
		}
	}, [currentProject.domain]);

	useEffect(() => {
		if (!configurationCompetitionList && mappedCompetitionListData.length > 0) {
			setConfigurationCompetitionList(mappedCompetitionListData[0].value);
		}
		fetchPreviewEvents(configurationDateAsObj || new Date(), false, configurationCompetitionList);
	}, [configurationCompetitionList]);

	useEffect(() => {
		setInitialCompetitionList(configurationCompetitionList);
	}, []);

	useEffect(() => {
		if (!initialFetchedData.length) {
			fetchPreviewEvents(configurationDateAsObj, false, configurationCompetitionList);
		}
	}, []);

	useEffect(() => {
		return () => resetMultisportDataRedux();
	}, []);

	const fetchPreviewEvents = (date: Date | null, changeDate: boolean = false, competitionList: string) => {
		if (isDateObjValid(date) && competitionList) {
			setPreviewEventsLoading(true);
			MultisportHttpService.getPreviewEventsByDate(date!!, competitionList)
				.then((response) => {
					if (response && response.data) {
						const result = response.data.map((responseSportData: Array<any>) => responseToMultisportModel(responseSportData));
						loadMultisportEntities(result);
						setInitialFetchedData(structuredClone(result));
					} else {
						loadMultisportEntities([]);
					}
				})
				.catch(() => toast.error(t('multisport_config_error_fetch')))
				.finally(() => {
					setPreviewEventsLoading(false);
					if (changeDate) {
						setConfigurationDate(date || new Date());
					}

					if (competitionList !== configurationCompetitionList) {
						setConfigurationCompetitionList(competitionList);
					}
				});
		}
	};

	const detectChangesAction = (date: Date | null, competitionList: SelectOption) => {
		const hasDateChanged = !areInitialFetchedDataAndPreviewDataEqual;
		const hasCompetitionListChanged = initialCompetitionList !== competitionList.value;

		if (hasDateChanged || (hasCompetitionListChanged && !areInitialFetchedDataAndPreviewDataEqual)) {
			displaySaveModal(date || undefined, competitionList.value);
		} else {
			setConfigurationDateAsObj(date || new Date());
			setConfigurationDate(date || new Date());
			setConfigurationCompetitionList(competitionList.value);

			fetchPreviewEvents(date || new Date(), false, competitionList.value);
		}
	};

	return (
		<>
			<div id={DATA_QA.MULTISPORT_WRAPPER}>
				<EventsList />
				<div id={DATA_QA.RIGHT_SECTION} className={isPreviewEventsLoading ? 'loading-overlay' : ''}>
					<ConfigureDataSection
						selectConfigureData={detectChangesAction}
						date={configurationDateAsObj}
						selectedCompetitionList={mappedSelectedCompetitionList}
					/>
					{previewData.length > 0 ? (
						setTopEventSectionAsFirstElement(previewData).map((previewEvents) => (
							<EventsPreview key={previewEvents.sport} previewEvents={previewEvents} />
						))
					) : (
						<div className={DATA_QA.EVENTS_PREVIEW_WRAPPER}>
							<h3> {t('automatic_multisport_data')} </h3>
						</div>
					)}
				</div>

				<RemovePreviewEntities />
				<SavePreviewEntities
					actionAfterCancel={(date: Date, competitionList: string) => fetchPreviewEvents(date, true, competitionList)}
					actionAfterSave={setInitialFetchedData}
				/>
			</div>
			<div id={DATA_QA.FOOTER_ACTIONS_WRAPPER}>
				<RoundedBlueButton title={t('save_changes')} text={t('save_changes')} onClickAction={displaySaveModal} />
			</div>
		</>
	);
};

function mapStateToProps(state: any) {
	return {
		previewData: state.multisportConfiguration.previewData || [],
		configurationDate: state.multisportConfiguration.configurationDate || null,
		configurationCompetitionList: state.multisportConfiguration.configurationCompetitionList || null,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		loadMultisportEntities: (data: MultisportModel[]) => dispatch(loadMultisportEntities(data)),
		displaySaveModal: (changedConfigDate?: Date, competitionList?: string) => {
			const validDate = isDateObjValid(changedConfigDate) ? changedConfigDate : undefined;
			dispatch(pushDataToModal(MultisportModalsType.Save, validDate, competitionList));
		},
		setConfigurationDate: (date: Date) => dispatch(setMultisportConfigurationDate(date)),
		setConfigurationCompetitionList: (competitionList: string) => dispatch(setMultisportConfigurationCompetitionList(competitionList)),
		resetMultisportDataRedux: () => dispatch(resetMultisportData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MultisportContainer);
