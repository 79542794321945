import { ListItemJson } from './ListItemJson';
import ListItemBuilder from './ListItemBuilder';
import ListItemMeta from './list-item-meta/ListItemMeta';

export default class ListItem {
	readonly data: any;
	readonly meta: ListItemMeta;
	readonly type: string;
	readonly weight: number;
	readonly is_automatic_content?: boolean;
	readonly from?: string;
	readonly to?: string;

	constructor(data: any, meta: ListItemMeta, type: string, weight: number, is_automatic_content?: boolean, from?: string, to?: string) {
		this.data = data;
		this.meta = meta;
		this.type = type;
		this.weight = weight;
		this.is_automatic_content = is_automatic_content;
		this.from = from;
		this.to = to;
	}

	toJSON(): ListItemJson {
		return {
			data: this.data,
			meta: this.meta,
			type: this.type,
			is_automatic_content: this.is_automatic_content,
			weight: this.weight,
			from: this.from,
			to: this.to,
		};
	}

	static fromJSON(json: ListItemJson): ListItem {
		return new ListItem(json.data, json.meta, json.type, json.weight, json.is_automatic_content, json.from, json.to);
	}

	static builder(list?: ListItem): ListItemBuilder {
		return new ListItemBuilder(list);
	}
}
