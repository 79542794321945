import ListBuilder from './ListBuilder';
import { ListJson } from './ListJson';
import ListConfiguration from './configuration/ListConfiguration';
import AutomaticListCriteria from './criteria/AutomaticListCriteria';
import ListItem from './list-item/ListItem';
import PopularitySettings from './popularity-settings/PopularitySettings';

export default class List {
	readonly id: string;
	readonly title: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly slug: string;
	readonly configuration: ListConfiguration;
	readonly items: ListItem[];
	readonly type: string;
	readonly status: string;
	readonly lockedPositions: number[];
	readonly authors: string[];
	readonly language: string;
	readonly contentType: string;
	readonly automaticContentThreshold: string;
	readonly criteria: AutomaticListCriteria;
	readonly automaticContent: boolean | null;
	readonly automaticContentMode: string;
	readonly popularitySettings: PopularitySettings;

	private constructor(
		id: string,
		title: string,
		createdAt: string,
		updatedAt: string,
		slug: string,
		configuration: ListConfiguration,
		items: ListItem[],
		type: string,
		status: string,
		lockedPositions: number[],
		authors: string[],
		language: string,
		contentType: string,
		automaticContentThreshold: string,
		criteria: AutomaticListCriteria,
		automaticContent: boolean | null,
		automaticContentMode: string,
		popularitySettings: PopularitySettings,
	) {
		this.id = id;
		this.title = title;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.slug = slug;
		this.configuration = configuration;
		this.items = items;
		this.type = type;
		this.status = status;
		this.lockedPositions = lockedPositions;
		this.authors = authors;
		this.language = language;
		this.contentType = contentType;
		this.automaticContentThreshold = automaticContentThreshold;
		this.criteria = criteria;
		this.automaticContent = automaticContent;
		this.automaticContentMode = automaticContentMode;
		this.popularitySettings = popularitySettings;
	}

	toJSON(): ListJson {
		return {
			id: this.id,
			title: this.title,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			slug: this.slug,
			configuration: this.configuration,
			items: this.items,
			type: this.type,
			status: this.status,
			lockedPositions: this.lockedPositions,
			authors: this.authors,
			language: this.language,
			contentType: this.contentType,
			automaticContentThreshold: this.automaticContentThreshold,
			criteria: this.criteria,
			automaticContent: this.automaticContent,
			automaticContentMode: this.automaticContentMode,
			popularitySettings: this.popularitySettings,
		};
	}

	static fromJSON(json: ListJson): List {
		return new List(
			json.id,
			json.title,
			json.createdAt,
			json.updatedAt,
			json.slug,
			json.configuration,
			json.items,
			json.type,
			json.status,
			json.lockedPositions,
			json.authors,
			json.language,
			json.contentType,
			json.automaticContentThreshold,
			json.criteria,
			json.automaticContent,
			json.automaticContentMode,
			json.popularitySettings,
		);
	}

	static builder(list?: List): ListBuilder {
		return new ListBuilder(list);
	}
}
