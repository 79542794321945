import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { store } from '../../../../store/store';
import { GenericOptionType } from '../../../../constants/select';

type Properties = {
	inputId: string;
	isClearable?: boolean;
	options: GenericOptionType<string>[];
	automaticContentMode: string;
	onDataSourceSelect: (value: string) => void;
	onAutomaticContentModeSelect: (value: string) => void;
};

const AutomaticContentSelect: React.FunctionComponent<Properties> = (props) => {
	const { inputId, isClearable, options, automaticContentMode, onDataSourceSelect, onAutomaticContentModeSelect } = props;
	const [t] = useTranslation();

	const features = store.getState().project.currentProject.features;
	const feature = features.find(({ id }) => id === FeatureTypes.AUTOMATIC_LISTS);
	const isPopularityEnabled = feature ? feature.data.popularity_enabled : false;
	const dataSource = feature ? feature.data.data_source : '';

	const optionsByFeature = useMemo(() => {
		if (isPopularityEnabled) {
			return options;
		}

		return [options[0]];
	}, [isPopularityEnabled, t]);

	const selectedValue = optionsByFeature.find((option) => option.value === automaticContentMode);

	const onChange = (option: GenericOptionType<string> | null) => {
		option ? onAutomaticContentModeSelect(option.value) : onAutomaticContentModeSelect('');
	};

	useEffect(() => {
		if (selectedValue) {
			onDataSourceSelect(dataSource);
		}
	}, [selectedValue]);

	return (
		<>
			<Select
				inputId={inputId}
				menuPortalTarget={document.body}
				menuPosition='absolute'
				isClearable={isClearable}
				placeholder={t('select')}
				options={optionsByFeature}
				value={selectedValue}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				onChange={(selection) => onChange(selection as GenericOptionType<string> | null)}
				className={'mb-1'}
			/>
		</>
	);
};

export default AutomaticContentSelect as React.ComponentType<Properties>;
