import ListItem from '../../../../models/list/list-item/ListItem';
import List from '../../../../models/list/List';
import { ListContentTypes } from '../../../../constants/content-types';
import i18next from 'i18next';

export const remapListToFitOptions = (items: any, t: any) => {
	if (items && items.length > 0) {
		return items.map((item: ListItem) => {
			if (item) {
				return {
					id: item.data && item.data.id,
					name: `${t('position')} ${item.weight + 1}`,
					...item,
				};
			}
		});
	}
	return [];
};

export const lockedItems = (list: List) => {
	if (list.lockedPositions && list.lockedPositions.length > 0) {
		return list.lockedPositions.map((position: number) => list.items[position]);
	}
	return [];
};

export const setListLockedPositions = (selections: any[], list: List) => {
	if (selections && selections.length > 0 && list && list.items && list.items.length > 0) {
		return selections.map((selection: any) => {
			return list.items.findIndex((item: any) => item.data.id === selection.value);
		});
	}
	return [];
};

export const swap = (items: ListItem[], oldIndex: number, target: number) => {
	let temp = items[oldIndex];
	items[oldIndex] = items[target];
	items[target] = temp;
};

export const moveArrayItemWithSkips = (items: ListItem[], positionsToSkip: number[], oldIndex: number, newIndex: number) => {
	// Parameters check
	if (
		!items ||
		items.length === 0 ||
		oldIndex === newIndex ||
		oldIndex < 0 ||
		newIndex < 0 ||
		oldIndex >= items.length ||
		newIndex >= items.length ||
		(oldIndex < newIndex && oldIndex > newIndex) ||
		positionsToSkip.includes(oldIndex) ||
		positionsToSkip.includes(newIndex)
	) {
		return items;
	}
	// Step to add depends of locked items.
	let stepAdd = oldIndex > newIndex ? -1 : 1;

	do {
		let target = oldIndex + stepAdd;
		// Items in positionsToSkip should be unmovable, so we skip them as target for move and increase stepAdd.
		while (positionsToSkip.includes(target)) {
			target += stepAdd;
		}
		// Replace items in the new positions
		swap(items, oldIndex, target);
		oldIndex = target;
	} while (oldIndex < newIndex || oldIndex > newIndex);

	// Return reordered array
	return items;
};

export const isManualEditorialLists = (): boolean => {
	return window.location.hash.includes('#/smp/lists');
};

export const isAutomatedLists = (): boolean => {
	return window.location.hash.includes('#/smp/automated-lists');
};

export const isScheduledLists = (): boolean => {
	return window.location.hash.includes('#/smp/scheduled-lists');
};

export const isTagsSportConnectionsLists = (): boolean => {
	return window.location.hash.includes('#/smp/tags-and-sports-connections-lists');
};

export const getListType = (): ListContentTypes => {
	if (isScheduledLists()) {
		return ListContentTypes.SCHEDULED;
	}
	if (isAutomatedLists()) {
		return ListContentTypes.AUTOMATED;
	} else if (isTagsSportConnectionsLists()) {
		return ListContentTypes.METADATA;
	}

	return ListContentTypes.EDITORIAL;
};

export const getListTypeTranslated = (): string => {
	const listContentType = getListType();
	switch (listContentType) {
		case ListContentTypes.SCHEDULED:
			return i18next.t('scheduled_lists');
		case ListContentTypes.AUTOMATED:
			return i18next.t('automated');
		case ListContentTypes.METADATA:
			return i18next.t('tags_and_sports_lists');
		default:
			return i18next.t('editorial_lists');
	}
};

export const getListTypeContentTranslated = (): string => {
	const listContentType = getListType();
	switch (listContentType) {
		case ListContentTypes.SCHEDULED:
			return i18next.t('scheduled_list_content');
		case ListContentTypes.AUTOMATED:
			return i18next.t('automated_list_content');
		case ListContentTypes.METADATA:
			return i18next.t('tags_and_sport_connections_content');
		default:
			return i18next.t('editorial_content');
	}
};

type EditsUrl = {
	editUrl: string;
	editContentUrl: string;
};

export const getListTypeEditsUrl = (contentId: string): EditsUrl => {
	const location = window.location.hash;
	const result: EditsUrl = {
		editUrl: '',
		editContentUrl: '',
	};

	const locationLists = {
		[ListContentTypes.SCHEDULED]: '/smp/scheduled-lists',
		[ListContentTypes.AUTOMATED]: '/smp/automated-lists',
		[ListContentTypes.METADATA]: '/smp/tags-and-sports-connections-lists',
		[ListContentTypes.EDITORIAL]: '/smp/lists',
	};

	let locationDetected = locationLists[ListContentTypes.EDITORIAL];

	if (location.includes(locationLists[ListContentTypes.SCHEDULED])) {
		locationDetected = locationLists[ListContentTypes.SCHEDULED];
	}
	if (location.includes(locationLists[ListContentTypes.AUTOMATED])) {
		locationDetected = locationLists[ListContentTypes.AUTOMATED];
	} else if (location.includes(locationLists[ListContentTypes.METADATA])) {
		locationDetected = locationLists[ListContentTypes.METADATA];
	}

	result.editUrl = `${locationDetected}/edit/${contentId}`;
	result.editContentUrl = `${locationDetected}/edit-content/${contentId}`;

	return result;
};

export const getListTypeCreateUrl = (): string => {
	const location = window.location.hash;

	switch (location) {
		case '#/smp/scheduled-lists':
			return '/smp/scheduled-lists/create';
		case '#/smp/automated-lists':
			return '/smp/automated-lists/create';
		case '#/smp/tags-and-sports-connections-lists':
			return '/smp/tags-and-sports-connections-lists/create';
		default:
			return '/smp/lists/create';
	}
};

// remove MIN/MAX ITEMS + LOCKED_POSITIONS from SCHEDULED lists
export const removeScheduledListProperties = (list: any): void => {
	if (list && list.contentType === ListContentTypes.SCHEDULED) {
		if (list.configuration) {
			delete list.configuration.minItems;
			delete list.configuration.maxItems;
		}
		delete list.lockedPositions;
	}
};

// we store the from/to dates in meta object
// but the api expects them in the parent item object
export const transferMetaDatesToParentObj = (list: any): void => {
	if (list && list.contentType !== ListContentTypes.SCHEDULED) {
		return;
	}

	if (list && list.items && list.items.length > 0) {
		list.items = list.items.map((listItem: ListItem) => {
			const listItemMeta = listItem.meta;
			if (listItemMeta && (listItemMeta.to || listItemMeta.from)) {
				return { ...listItem, from: listItemMeta.from, to: listItemMeta.to };
			}
			return listItem;
		});
	}
};

export const formatScheduleListProperties = (list: any) => {
	removeScheduledListProperties(list);
	transferMetaDatesToParentObj(list);
};
